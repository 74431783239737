import { Actions } from "../actions";
import { REQ_PERSIST_CREDIT_APPLICATION_DATA } from "../actions/credit-application";

const CREDIT_APPLICATION_KEY = "wayke-credit-application-props";
const setPersistedData = (ssn: string, email: string, estimatedMileage: string | number, telephone: string) => {
    if (!ssn || !email || !estimatedMileage || !telephone) {
        localStorage.removeItem(CREDIT_APPLICATION_KEY);
        return {};
    }
    try {
        localStorage.setItem(CREDIT_APPLICATION_KEY, JSON.stringify({ ssn, email, estimatedMileage, telephone }));
        return { ssn, email, estimatedMileage, telephone };
    } catch (e) {
        return { ssn, email, estimatedMileage, telephone };
    }
};
const getPersistedData = () => {
    let result = {};
    try {
        const persisted = localStorage.getItem(CREDIT_APPLICATION_KEY);

        if (persisted) {
            result = JSON.parse(persisted);
        }
    } catch (e) {
        result = {};
    }

    return result;
};

export interface Persisted {
    ssn?: string;
    email?: string;
    estimatedMileage?: string | number;
    telephone?: string;
}

interface CreditApplicationState {
    persisted: Persisted;
}

export const initialState: CreditApplicationState = {
    persisted: getPersistedData(),
};

const creditApplicationReducer = (
    state: CreditApplicationState = initialState,
    action: Actions,
): CreditApplicationState => {
    switch (action.type) {
        case REQ_PERSIST_CREDIT_APPLICATION_DATA:
            return {
                ...state,
                persisted: setPersistedData(action.ssn, action.email, action.estimatedMileage, action.telephone),
            };
        default:
            return state;
    }
};

export default creditApplicationReducer;
