import useRedirectors from "./useRedirectors";
import useSideEffects from "./useSideEffects";
import useUiTracker from "./useUiTracker";

const Middlewares = () => {
    useUiTracker();
    useSideEffects();
    useRedirectors();
    return null;
};

export default Middlewares;
