import { requestStatus, responseStatus } from "../shared/statuses";

import {
    REQ_ASSIGNMENTS_LIST,
    RCV_ASSIGNMENTS_LIST,
    REQ_CREATE_ASSIGNMENT,
    RCV_CREATE_ASSIGNMENT,
    REQ_REMOVE_ASSIGNMENT,
    RCV_REMOVE_ASSIGNMENT,
    REQ_ASSIGNMENT_RECIPIENTS,
    RCV_ASSIGNMENT_RECIPIENTS,
    REQ_ASSIGNMENT_STAKEHOLDERS,
    RCV_ASSIGNMENT_STAKEHOLDERS,
    REQ_CLEAR_ASSIGNMENTS,
    REQ_CLEAR_CREATED_ASSIGNMENT,
    REQUEST_FAILED,
} from "../actions/assignment";
import { Actions } from "../actions";
import { DocumentList } from "../@types/ElasticSearchResponse";
import { AssignmentIn, AssignmentPublishProps } from "../@types/assignment";
import { Retailer } from "../@types/Retailer";
import { Stakeholder } from "../@types/stakeholder";

interface AssignmentState {
    requestStatus: number;
    responseStatus: number;
    documentList: DocumentList<AssignmentIn> | null;
    assignment?: AssignmentPublishProps;
    recipientList: DocumentList<Retailer> | null;
    stakeholders: { [key: string]: Stakeholder[] | undefined };
}

export const initialState: AssignmentState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    documentList: null,
    recipientList: null,
    stakeholders: {},
};

const assignmentsReducer = (state: AssignmentState = initialState, action: Actions): AssignmentState => {
    let list: DocumentList<AssignmentIn> | null = null;
    let index = -1;

    switch (action.type) {
        case REQ_ASSIGNMENTS_LIST:
            return { ...state, requestStatus: requestStatus.FETCHING_LIST, responseStatus: responseStatus.PENDING };
        case RCV_ASSIGNMENTS_LIST:
            return {
                ...state,
                documentList: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_CREATE_ASSIGNMENT:
            return {
                ...state,
                requestStatus: requestStatus.CREATING,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_CREATE_ASSIGNMENT:
            return {
                ...state,
                assignment: action.response,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_REMOVE_ASSIGNMENT:
            return {
                ...state,
                requestStatus: requestStatus.REMOVING,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_REMOVE_ASSIGNMENT:
            list = state.documentList ? { ...state.documentList } : null;
            if (state.documentList && list?.documents) {
                index = list.documents.findIndex((d) => d.id === action.response.id);
                if (index >= 0) {
                    list.documents[index].status = "Unpublished";
                }
            }
            return {
                ...state,
                documentList: list,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_ASSIGNMENT_RECIPIENTS:
            return {
                ...state,
                recipientList: null,
                requestStatus: requestStatus.FETCHING_LIST,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_ASSIGNMENT_RECIPIENTS:
            return {
                ...state,
                recipientList: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_ASSIGNMENT_STAKEHOLDERS:
            return state;
        case RCV_ASSIGNMENT_STAKEHOLDERS:
            return {
                ...state,
                stakeholders: {
                    ...state.stakeholders,
                    [action.id]: action.response,
                },
            };
        case REQUEST_FAILED:
            return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        case REQ_CLEAR_ASSIGNMENTS:
            return { ...initialState, recipientList: state.recipientList };
        case REQ_CLEAR_CREATED_ASSIGNMENT:
            return { ...state, assignment: undefined };
        default:
            return state;
    }
};

export default assignmentsReducer;
