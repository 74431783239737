/* eslint-disable no-warning-comments */
import { settings } from "../helpers";
import { useTranslation } from "react-i18next";
import { getMarket } from "../i18n";

const resolveCustomerServiceRoute = () => {
    const { marketCode } = getMarket();
    switch (marketCode) {
        case "NO":
            return "/kundeservice";
        default:
            return "/kundservice";
    }
};

const resolveAboutRoute = () => {
    const { marketCode } = getMarket();
    switch (marketCode) {
        case "NO":
            return "/om-drive";
        default:
            return "/om-wayke";
    }
};

const resolvePressRoute = () => {
    const { marketCode } = getMarket();
    switch (marketCode) {
        case "NO":
            return "/presse-og-annonsering";
        default:
            return "/press-och-annonsering";
    }
};

const resolveGetStartedRoute = () => {
    const { marketCode } = getMarket();
    switch (marketCode) {
        case "NO":
            return "/kom-i-gang-med-drive";
        default:
            return "/kom-igang-med-wayke";
    }
};

const resolveContactRoute = () => {
    const { marketCode } = getMarket();
    switch (marketCode) {
        case "NO":
            return "/kontakt-oss";
        default:
            return "/kontakta-wayke";
    }
};

export default () => {
    const { t } = useTranslation(["global", "navigation"]);
    const { marketCode } = getMarket();
    const showCareer = marketCode === "SE";
    const showDealerLogin = marketCode === "SE";
    const showContactPage = marketCode === "NO";
    const showPressAndAdvertising = marketCode === "SE";

    return {
        [t("brandName", { ns: "global" })]: {
            [t("Getting started", { ns: "navigation" })]: resolveGetStartedRoute(),
            [t("About", { ns: "navigation" })]: resolveAboutRoute(),
            ...(showPressAndAdvertising && { [t("Press & Advertising", { ns: "navigation" })]: resolvePressRoute() }),
            ...(showCareer && {
                [t("Career", { ns: "navigation" })]: {
                    url: "https://jobs.wayke.se",
                    rel: "noopener",
                    target: "_blank",
                    aTag: true,
                },
            }),
        },
        [t("Contact and help", { ns: "navigation" })]: {
            [t("Customer support", { ns: "navigation" })]: resolveCustomerServiceRoute(),
            ...(showContactPage && {
                [t("Contact", { ns: "navigation" })]: resolveContactRoute(),
            }),
        },
        [t("For companies", { ns: "navigation" })]: {
            ...(showDealerLogin && {
                [t("Dealer log in", { ns: "navigation" })]: {
                    url: settings.get("DealerUiUri"),
                    rel: "noopener",
                    target: "_blank",
                    aTag: true,
                },
            }),
            [t("SitePartner", { ns: "navigation" })]: {
                url: settings.get("partnerSiteUri"),
                rel: "noopener",
                target: "_blank",
                aTag: true,
            },
        },
    };
};
