import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";

import { ElasticSearchResponse } from "../../@types/ElasticSearchResponse";
import { ElasticVehicle } from "../../@types/ElasticVehicle";
import { FsInstance } from "../../@types/global";
import { RCV_ACCESS_TKN, refreshAccessToken } from "../../actions/auth";
import { EVENT_TOGGLE_MENU } from "../../actions/event";
import { RCV_SEARCH } from "../../actions/search";
import QueryFilter from "../../helpers/query-filter";
import { RootState } from "../../reducers";

interface FreeSpeeWindow extends Window {
    __fs_dncs_instance?: FsInstance;
}

declare const window: FreeSpeeWindow;

const useFilterUrlRewrite = () => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        searchQueryParams?: string;
        response: ElasticSearchResponse<ElasticVehicle>;
    };
    const { pathname, search } = useLocation();
    const { replace } = useHistory();

    useEffect(() => {
        if (
            !process.env.SSR &&
            action.type === RCV_SEARCH &&
            pathname.substring(0, 4) === "/sok" &&
            !QueryFilter.create(action.searchQueryParams).isSame(QueryFilter.create(action.response.query?.parameters))
        ) {
            const queryFilter = QueryFilter.create(action.response.query?.parameters);
            const list = new URLSearchParams(search).get("list");
            const queryUrl = queryFilter.toQueryURL();
            const appendQuestion = queryUrl.indexOf("?") === -1;
            const listQuery = list ? `${appendQuestion ? "?" : "&"}list=${list}` : "";
            const url = `${pathname}${queryUrl}${listQuery}`;
            return replace(url);
        }
    }, [action]);
};

const MainNavClass = "main-nav-is-open";
const usebodyClassMainNav = () => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
    };
    const current = useSelector<RootState, boolean>((s) => s.events.showMobileMenu);

    useEffect(() => {
        if (action.type === EVENT_TOGGLE_MENU) {
            if (action.type === EVENT_TOGGLE_MENU && current) {
                document.body.className = document.body.className.replace(MainNavClass, "");
            } else if (action.type === EVENT_TOGGLE_MENU && !current) {
                document.body.className += ` ${MainNavClass}`;
            }
        }
    }, [action]);
};

const useTokenRevalidationAction = (dispatch: Dispatch<AnyAction>) => {
    const timeoutRef = useRef<NodeJS.Timeout>();
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        response?: {
            revalidate?: boolean;
        };
    };

    useEffect(() => {
        if (action.type === RCV_ACCESS_TKN && action.response?.revalidate) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => refreshAccessToken()(dispatch), 250);
        }
    }, [action]);
};

const useFreespeeRouteChange = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname) {
            if (window.__fs_dncs_instance) {
                window.__fs_dncs_instance.trackPage();
            }
        }
    }, [pathname]);
};

const useSideEffects = () => {
    const dispatch = useDispatch();

    useFilterUrlRewrite();
    usebodyClassMainNav();
    useTokenRevalidationAction(dispatch);
    useFreespeeRouteChange();
};

export default useSideEffects;
