import { Actions } from "../actions";
import {
    EVENT_MESSAGE_PIPE,
    EVENT_TOGGLE_MENU,
    EVENT_PRODUCT_LIST_SORT_VISIBLE,
    EVENT_SHOW_ECOM_MODAL,
    EVENT_SHOW_MESSAGE_MODAL,
    EVENT_HIDE_MESSAGE_MODAL,
} from "../actions/event";
import { RCV_ROUTING } from "../actions/routing";

interface EventsState {
    showMobileMenu: boolean;
    showAdOrAssignmentModal: boolean;
    lastPipeMessage: string | null;
    productListSortVisible: boolean;
    showEcomModal: boolean;
    showMessageModal: boolean;
}

export const initialState: EventsState = {
    showMobileMenu: false,
    showAdOrAssignmentModal: false,
    lastPipeMessage: null,
    productListSortVisible: false,
    showEcomModal: false,
    showMessageModal: false,
};

const eventsReducer = (state: EventsState = initialState, action: Actions): EventsState => {
    switch (action.type) {
        case EVENT_MESSAGE_PIPE:
            return { ...state, lastPipeMessage: action.message };
        case EVENT_TOGGLE_MENU:
            return { ...state, showMobileMenu: !state.showMobileMenu };
        case EVENT_SHOW_ECOM_MODAL:
            return { ...state, showEcomModal: true };
        case EVENT_SHOW_MESSAGE_MODAL:
            return { ...state, showMessageModal: true };
        case EVENT_HIDE_MESSAGE_MODAL:
            return { ...state, showMessageModal: false };
        case EVENT_PRODUCT_LIST_SORT_VISIBLE:
            return { ...state, productListSortVisible: action.type === EVENT_PRODUCT_LIST_SORT_VISIBLE };
        case RCV_ROUTING:
            return { ...state, showAdOrAssignmentModal: false };
        default:
            return state;
    }
};

export default eventsReducer;
