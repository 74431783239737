import { QueryClient } from "react-query";
import { ArticleAuthor } from "../../@types/ArticleV2";
import { ElasticSearchResponse } from "../../@types/ElasticSearchResponse";
import { ApiResponse } from "../../Http";
import { SSR_NEW } from "../../QueryClientSetup";

const registerSsrAuthor = (queryClient: QueryClient, serverState: SSR_NEW) => {
    const state = serverState.author?.result?.documentList;
    const initialData: ApiResponse<ElasticSearchResponse<ArticleAuthor>> | undefined = state
        ? {
              response: {
                  facets: [],
                  documentList: {
                      ...state,
                  },
              },
              successful: true,
          }
        : undefined;

    if (initialData) {
        const slug = initialData.response.documentList.documents?.[0]?.authorSlug;
        queryClient.setQueryData([`/api/author/${slug}`], initialData);
    }

    return queryClient;
};

export default registerSsrAuthor;
