import React from "react";
import Link from "../link";

interface FooterLinkProps {
    id: string;
    link: string | Record<string, string>;
}

const FooterLink = ({ id, link }: FooterLinkProps) => {
    let markup = null;
    if (typeof link === "string") {
        markup = (
            <Link to={link} className="footer-link" title={id}>
                {id}
            </Link>
        );
    } else if (typeof link === "object") {
        const { url } = link;
        if (!link.aTag) {
            markup = (
                <Link to={url} className="footer-link" title={id}>
                    {id}
                </Link>
            );
        } else {
            const { rel, target } = link;
            markup = (
                <a href={url} className="footer-link" title={id} rel={rel} target={target}>
                    {id}
                </a>
            );
        }
    }

    return <li className="m-b-mini">{markup}</li>;
};

export default FooterLink;
