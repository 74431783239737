﻿import { requestStatus, responseStatus } from "../shared/statuses";

import {
    RCV_ACCESS_TKN,
    REQ_PROFILE,
    RCV_PROFILE,
    REQ_UPDATE_PROFILE,
    RCV_UPDATE_PROFILE,
    REQUEST_FAILED,
} from "../actions/auth";
import { Actions } from "../actions";
import { ProfileFull } from "../@types/Profile";

interface AuthState {
    requestStatus: number;
    responseStatus: number;
    token: string | null;
    profile: ProfileFull | null;
}

export const initialState: AuthState = {
    requestStatus: requestStatus.FETCHING,
    responseStatus: responseStatus.OK,
    token: null,
    profile: null,
};

const authReducer = (state = initialState, action: Actions): AuthState => {
    switch (action.type) {
        case RCV_ACCESS_TKN:
            return {
                ...state,
                requestStatus: action.response.accessToken ? state.requestStatus : requestStatus.WAITING,
                token: action.response.accessToken,
            };
        case REQ_PROFILE:
            return {
                ...state,
                profile: null,
                requestStatus: requestStatus.FETCHING,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_PROFILE:
            return {
                ...state,
                profile: action.response,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_UPDATE_PROFILE:
            return { ...state, requestStatus: requestStatus.UPDATING, responseStatus: responseStatus.PENDING };
        case RCV_UPDATE_PROFILE:
            return {
                ...state,
                profile: action.response,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQUEST_FAILED:
            return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};

export default authReducer;
