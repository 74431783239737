import { createGlobalStyle, css } from "styled-components";
import { MarketCode } from "../../@types/MarketCode";

import OpenSauceOneRegularWoff from "../../../font/OpenSauceOne/OpenSauceOne-Regular.woff";
import OpenSauceOneRegularWoff2 from "../../../font/OpenSauceOne/OpenSauceOne-Regular.woff2";
import OpenSauceOneBoldWoff from "../../../font/OpenSauceOne/OpenSauceOne-ExtraBold.woff";
import OpenSauceOneBoldWoff2 from "../../../font/OpenSauceOne/OpenSauceOne-ExtraBold.woff2";

import StyreneALightWoff from "../../../font/StyreneA/StyreneA-Light-Web.woff";
import StyreneALightWoff2 from "../../../font/StyreneA/StyreneA-Light-Web.woff2";
import StyreneARegularWoff from "../../../font/StyreneA/StyreneA-Regular-Web.woff";
import StyreneARegularWoff2 from "../../../font/StyreneA/StyreneA-Regular-Web.woff2";
import StyreneAMediumWoff from "../../../font/StyreneA/StyreneA-Medium-Web.woff";
import StyreneAMediumWoff2 from "../../../font/StyreneA/StyreneA-Medium-Web.woff2";
import StyreneABoldWoff from "../../../font/StyreneA/StyreneA-Bold-Web.woff";
import StyreneABoldWoff2 from "../../../font/StyreneA/StyreneA-Bold-Web.woff2";

import GeneralSansRegularWoff from "../../../font/GeneralSans/GeneralSans-Regular.woff";
import GeneralSansRegularWoff2 from "../../../font/GeneralSans/GeneralSans-Regular.woff2";
import GeneralSansSemiBoldWoff from "../../../font/GeneralSans/GeneralSans-Semibold.woff";
import GeneralSansSemiBoldWoff2 from "../../../font/GeneralSans/GeneralSans-Semibold.woff2";
import GeneralSansBoldWoff from "../../../font/GeneralSans/GeneralSans-Bold.woff";
import GeneralSansBoldWoff2 from "../../../font/GeneralSans/GeneralSans-Bold.woff2";

const resolvePath = (url: string, path?: string) => (path ? `${path}${url.slice(1)}` : url);

export default createGlobalStyle<{ market: MarketCode; path?: string }>`
    ${({ market, path }) =>
        market === "NO"
            ? css`
                  @font-face {
                      font-family: "General Sans";
                      src: url(${resolvePath(GeneralSansRegularWoff2, path)}) format("woff2"),
                          url(${resolvePath(GeneralSansRegularWoff, path)}) format("woff");
                      font-weight: 400;
                      font-display: swap;
                  }

                  @font-face {
                      font-family: "General Sans";
                      src: url(${resolvePath(GeneralSansSemiBoldWoff2, path)}) format("woff2"),
                          url(${resolvePath(GeneralSansSemiBoldWoff, path)}) format("woff");
                      font-weight: 600;
                      font-display: swap;
                  }

                  @font-face {
                      font-family: "General Sans";
                      src: url(${resolvePath(GeneralSansBoldWoff2, path)}) format("woff2"),
                          url(${resolvePath(GeneralSansBoldWoff, path)}) format("woff");
                      font-weight: 700;
                      font-display: swap;
                  }

                  :root {
                      --font-family: "General Sans", sans-serif;
                      --font-weight-light: 400;
                      --font-weight-regular: 400;
                      --font-weight-medium: 600;
                      --font-weight-bold: 800;
                  }
              `
            : market === "SE"
            ? css`
                  @font-face {
                      font-family: "Styrene A";
                      src: url(${resolvePath(StyreneALightWoff2, path)}) format("woff2"),
                          url(${resolvePath(StyreneALightWoff, path)}) format("woff");
                      font-weight: 300;
                      font-display: swap;
                  }

                  @font-face {
                      font-family: "Styrene A";
                      src: url(${resolvePath(StyreneARegularWoff2, path)}) format("woff2"),
                          url(${resolvePath(StyreneARegularWoff, path)}) format("woff");
                      font-weight: 400;
                      font-display: swap;
                  }

                  @font-face {
                      font-family: "Styrene A";
                      src: url(${resolvePath(StyreneAMediumWoff2, path)}) format("woff2"),
                          url(${resolvePath(StyreneAMediumWoff, path)}) format("woff");
                      font-weight: 500;
                      font-display: swap;
                  }

                  @font-face {
                      font-family: "Styrene A";
                      src: url(${resolvePath(StyreneABoldWoff2, path)}) format("woff2"),
                          url(${resolvePath(StyreneABoldWoff, path)}) format("woff");
                      font-weight: 700;
                      font-display: swap;
                  }

                  :root {
                      --font-family: "Styrene A", sans-serif;
                      --font-weight-light: 300;
                      --font-weight-regular: 400;
                      --font-weight-medium: 500;
                      --font-weight-bold: 700;
                  }
              `
            : css`
                  @font-face {
                      font-family: "Open Sauce One";
                      src: url(${resolvePath(OpenSauceOneRegularWoff2, path)}) format("woff2"),
                          url(${resolvePath(OpenSauceOneRegularWoff, path)}) format("woff");
                      font-weight: 400;
                      font-display: swap;
                  }

                  @font-face {
                      font-family: "Open Sauce One";
                      src: url(${resolvePath(OpenSauceOneBoldWoff2, path)}) format("woff2"),
                          url(${resolvePath(OpenSauceOneBoldWoff, path)}) format("woff");
                      font-weight: 800;
                      font-display: swap;
                  }

                  :root {
                      --font-family: "General Sans", sans-serif;
                      --font-weight-light: 400;
                      --font-weight-regular: 400;
                      --font-weight-medium: 800;
                      --font-weight-bold: 800;
                  }
              `}
`;
