import { requestStatus, responseStatus } from "../shared/statuses";

import {
    REQ_SEARCH,
    RCV_SEARCH,
    REQ_SEARCH_FACETS,
    RCV_SEARCH_FACETS,
    REQ_CLEAR,
    REQUEST_FAILED,
} from "../actions/search";
import { Actions } from "../actions";
import { ElasticVehicle } from "../@types/ElasticVehicle";
import { DocumentList, Facet, Filter } from "../@types/ElasticSearchResponse";

export const initialState: SearchState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    queryFilter: null,
    facets: [],
    facetList: [],
    initialHitCount: -1,
    hitCount: 0,
    documentList: null,
    initialized: false,
};

const convertFilters = (filters: Filter[]) => {
    /*
    Backend varies with formats for different facets. E.g. segment facent and color facets are returned in two different formats.
    The color format is supported in the frontend, but the segment one is not. This code transforms the facets with the invalid
    format to the correct format.

    Incorrect format:
    [
        {
            displayName: 'Volvo',
            selected: false
        }
        ...
        {
            displayName: 'exclude:Volvo',
            selected: true
        }
    ]

    Correct format:
    [
        {
            displayName: 'Volvo',
            selected: true
        }
        ...
    ]
    */

    return filters
        .filter((f) => {
            const displayName = f.displayName || "";
            const isExcluded = displayName.startsWith("exclude:");

            return !isExcluded;
        })
        .map((f) => {
            const isExcluded = !!filters.find((g) => g.displayName === `exclude:${f.displayName}`);

            if (isExcluded) {
                return {
                    ...f,
                    selected: true,
                };
            }
            return f;
        });
};

const convertFacets = (facets?: Facet[] | null): Facet[] | undefined => {
    return facets?.map((f) => ({ ...f, filters: convertFilters(f.filters) }));
};

interface SearchState {
    requestStatus: number;
    responseStatus: number;
    queryFilter: string | null;
    facets?: Facet[];
    facetList: Facet[] | null;
    initialHitCount?: number;
    hitCount?: number;
    documentList: DocumentList<ElasticVehicle> | null;
    initialized: boolean;
    notFound?: boolean;
}

const searchReducer = (state: SearchState = initialState, action: Actions): SearchState => {
    switch (action.type) {
        case REQ_SEARCH:
            return {
                ...state,
                documentList: null,
                queryFilter: action.searchQueryParams,
                requestStatus: requestStatus.FETCHING_LIST,
                responseStatus: responseStatus.PENDING,
                initialized: state.initialized && state.documentList === null,
                notFound: false,
            };
        case RCV_SEARCH:
            const initialHitCount =
                state.initialHitCount && state.initialHitCount > 0
                    ? state.initialHitCount
                    : action.response.documentList.numberOfHits;

            return {
                ...state,
                documentList: action.response.documentList,
                facets: convertFacets(action.storeFacets ? action.response.facets : state.facets),
                hitCount: action.response.documentList.numberOfHits,
                initialHitCount,
                queryFilter: action.response.query ? action.response.query.parameters : "",
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_SEARCH_FACETS:
            return state;
        case RCV_SEARCH_FACETS:
            return { ...state, facetList: action.response.facets, initialHitCount: action.response.stats?.totalHits };
        case REQ_CLEAR:
            return {
                ...initialState,
                facetList: state.facetList,
                hitCount: state.initialHitCount && state.initialHitCount > 0 ? state.initialHitCount : state.hitCount,
                initialHitCount: state.initialHitCount,
                initialized: false,
                notFound: false,
            };
        case REQUEST_FAILED:
            return {
                ...state,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.FAILED,
            };
        default:
            return state;
    }
};

export default searchReducer;
