import React, { useCallback, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Link from "../link";
import { urls, QueryFilter } from "../../helpers";
import { CombinedFacet, getFacetList } from "../../utils/reselectors";
import { search } from "../../actions/search";
import { getMarket } from "../../i18n";

const cloudLinksSE = {
    Volvo: [
        "V60",
        "XC40",
        "XC60",
        "V90",
        "XC90",
        "V60 Cross Country",
        "C40",
        "V40",
        "V90 Cross Country",
        "S60",
        "S90",
        "EX30",
        "XC40 Rechange Single Motor Extended Range",
        "XC40 Rechare Single Motor",
        "XC60 T6 AWD Recharge",
    ],
    Volkswagen: [
        "Golf",
        "Passat",
        "Tiguan",
        "Transporter",
        "ID.4",
        "T-Roc",
        "Passat Sportscombi",
        "ID.3",
        "ID.3 Pro Performance",
        "Caddy",
        "T-Cross",
        "ID.5",
        "ID.4 GTX",
        "Golf Sportscombi",
        "ID.4 Pro Performance",
        "Crafter",
        "Amorak",
    ],
    BMW: [
        "5-serie",
        "3-serie",
        "X3",
        "1-serie",
        "X1",
        "4-serie",
        "530e xDrive Touring",
        "118i",
        "iX",
        "520d xDrive Touring",
        "X5",
        "330e xDrive Touring",
        "i3",
        "X3 xDrive30e",
        "iX xDrive40",
        "iX1 xDrive30",
        "iX3",
    ],
    Audi: [
        "A6",
        "A3",
        "A4",
        "Q5",
        "Q2",
        "A6 Avant 40 TDI quattro",
        "e-tron",
        "A5",
        "Q3",
        "A3 Sportback 35 TFSI",
        "Q4 e-tron",
        "e-tron 55 quattro",
        "A1",
        "A4 Avant",
        "Q2 35 TFSI",
        "A6 Avant",
    ],
    Toyota: [
        "RAV4",
        "Corolla",
        "Yaris",
        "C-HR",
        "Yaris Hybrid",
        "C-HR Hybrid",
        "Corolla Touring Sports Hybrid",
        "RAV4 Hybrid AWD-i",
        "RAV4 LAddhybrid",
        "Corolla Hybrid",
        "ProAce",
        "Auris",
        "ProAce Skåpbil Electric",
        "ProAce Skåpbil",
        "RAV4 Plug-in Hybrid",
        "bZ4X",
    ],
    Renault: [
        "Kangoo",
        "Trafic",
        "Clio",
        "Captur",
        "Trafic Skåpbil",
        "ZOE",
        "Master",
        "Mégane",
        "Kangoo E-Tech",
        "ZOE R110",
        "Mégane E-Tech",
        "Express",
        "Mégane Sport Tourer",
        "ZOE R135",
        "Kangoo Van",
    ],
    "Mercedes-Benz": [
        "E-Klass",
        "C-Klass",
        "CLA",
        "Sprinter",
        "A-Klass",
        "GLC",
        "Citan",
        "Vito",
        "eCitan",
        "GLE",
        "GLA",
        "A 180",
        "B-Klass",
        "GLC 220 d 4MATIC",
    ],
    Škoda: [
        "Octavia",
        "Octavia Kombi",
        "Superb",
        "Fabia",
        "Enyaq iV",
        "Kamiq",
        "Superb iV Kombi",
        "Enyaq iV 80",
        "Karoq",
        "Scala",
        "Octavia Kombi iV",
        "Enyaq iV 80X",
    ],
    Kia: [],
    Ford: [],
    Peugeot: [],
    SEAT: [],
    Opel: [],
    Hyundai: [],
    Citroën: [],
    Nissan: [],
    MINI: [],
    Porsche: [],
    Mazda: [],
    Lexus: [],
    Tesla: [],
    CUPRA: [],
    Dacia: [],
    Mitsubishi: [],
    Fiat: [],
    Honda: [],
    Maxus: [],
    Polestar: [],
};

const cloudLinksNO = {
    Toyota: [
        "RAV4",
        "Corolla",
        "RAV4 Plug-in Hybrid",
        "C-HR",
        "C-HR Hybrid",
        "Corolla Touring Sports Hybrid",
        "Yaris",
        "Yaris Hybrid",
        "RAV4 Hybrid AWD-i",
        "ProAce",
        "Corolla Hybrid",
        "ProAce Varebil",
        "bZ4X",
        "ProAce City",
        "Prius",
    ],
    Volkswagen: [
        "ID.3",
        "ID3 1st",
        "ID.4",
        "Golf",
        "Transporter",
        "Caddy",
        "Passat",
        "ID.4 Pro Performance",
        "e-Golf",
        "Caddy Varebil",
        "ID.4 GTX",
        "ID. Buzz",
        "Passat Variant GTE",
        "ID.5",
        "Tiguan",
        "ID.3 Pro Performance",
        "ID.5 GTX",
        "T-Roc",
    ],
    Volvo: [
        "XC60",
        "XC60 T6 AWD Recharge",
        "XC40",
        "XC90",
        "V60",
        "V90",
        "C40",
        "C40 Recharge Twin motor",
        "XC40 Recharge Twin motor",
        "XC40 P8 AWD Recharge",
        "S60",
        "XC40 Recharge Single Motor",
        "C40 Recharge Single Motor",
        "V90 Cross Country",
        "C40",
    ],
    Ford: [
        "Mustang Mach-E",
        "Kuga",
        "Kuga Plug-In Hybrid",
        "Transit Connect",
        "Focus",
        "Mustang Mach-E Long Range",
        "Mustang Mach-E Long Range AWD",
        "Ranger",
        "Mustang Mach-E Standard Range AWD",
        "Transit Custom",
        "Focus Stasjonsvogn",
        "Explorer",
        "Explorer Plug-In Hybrid",
        "Mustang Mach-E GT",
        "Ranger Dobbelthytte",
        "Puma",
    ],
    BMW: [
        "X1",
        "X1 xDrive25e",
        "i3",
        "i3 120 Ah",
        "X3",
        "iX",
        "X2",
        "X2 xDrive25e",
        "X5",
        "3 Series",
        "4 Series",
        "iX3",
        "5 Series",
        "iX xDrive40",
        "i4 M50",
    ],
    "Mercedes-Benz": [
        "EQC",
        "EQC 499",
        "GLC",
        "EQA",
        "Vito",
        "GLE",
        "C-Klasse",
        "EQB",
        "EQA 300 4MATIC",
        "EQB 300 4MATIC",
        "GLE 350 de 4MATIC",
        "Citan",
        "E-Klasse",
        "EQE SUV",
        "GLC 300 e 4MATIC",
        "Sprinter",
        "CLA",
    ],
    Peugeot: [
        "2008",
        "e-2008",
        "208",
        "3008",
        "e-208",
        "5008",
        "Expert",
        "Partner",
        "3008 HYBRID4 300",
        "308",
        "508",
        "e-Expert",
        "508 SW Hybrid",
        "3008 GT HYBRID4",
        "408",
    ],
    Audi: [
        "e-tron",
        "e-tron 55 quattro",
        "Q4 e-tron",
        "e-tron 50 quattro",
        "Q8 e-tron",
        "e-tron Sportback 55 quattro",
        "Q4 50 e-tron quattro",
        "A3",
        "e-tron GT",
        "A6",
        "e-tron Sportback 50 quattro",
        "Q7",
        "e-tron GT quattro",
        "A1",
        "Q8 50 e-tron",
        "A4",
        "Q5",
    ],
    Škoda: [],
    Suzuki: [],
    Nissan: [],
    Hyundai: [],
    Lexus: [],
    Renault: [],
    Kia: [],
    Mazda: [],
    Maxus: [],
    Citroën: [],
    Mitsubishi: [],
    Opel: [],
    BYD: [],
    MG: [],
    DS: [],
    Hongqi: [],
    Tesla: [],
    Jaguar: [],
    Polestar: [],
    Voyah: [],
};

const getObjectKey = (cL: Record<string, string[]>, children = true) =>
    Object.keys(cL).filter((cLP) => (children ? cL[cLP].length > 0 : cL[cLP].length === 0));

const getNestedLinks = (extended: boolean, links: Record<string, string[]>, parent: string) =>
    extended ? links[parent].slice() : links[parent].slice(0, 5);

const getQueryURLFacetValues = (pathStartsWith: string) => {
    const queryURL = pathStartsWith.substr(5);
    return queryURL
        .replace(/^\/|\/$/g, "")
        .split("/")
        .filter((q) => !!q);
};

const getQueryFilterFromQueryURL = (facets: CombinedFacet[], queryURLValues: string[]) => {
    if (queryURLValues.length === 0) return null;

    let queryFilter = new QueryFilter();
    facets.forEach((facet) =>
        facet.filters.forEach((filter) => {
            if (filter.displayName && queryURLValues.includes(urls.slugify(filter.displayName))) {
                if (facet.isRangeFacet) {
                    queryFilter = queryFilter.addRange(facet.id, 0, 100);
                } else {
                    queryFilter = queryFilter.add(facet.id, filter.displayName);
                }
            }
        }),
    );

    if (queryFilter.isEmpty()) return null;

    return queryFilter;
};

const DefaultFooterLinkCloud = () => {
    const { t } = useTranslation(["common", "footer"]);

    const dispatch = useDispatch();
    const facets = useSelector(getFacetList);
    const actions = bindActionCreators(
        {
            search,
        },
        dispatch,
    );

    // Set market specific cloud links
    const marketCode = getMarket().marketCode;
    const cloudLinks = marketCode == "NO" ? cloudLinksNO : cloudLinksSE;

    type CloudLinkKeyTypes = keyof typeof cloudLinks;

    const defaultExtended = Object.keys(cloudLinks).reduce((output: { [key: string]: boolean }, current) => {
        output[current] = true;
        return output;
    }, {});

    const defaultC = Object.keys(cloudLinks).reduce((output: { [key: string]: boolean }, current) => {
        output[current] = false;
        return output;
    }, {});

    const [extended, setExtended] = React.useState<{ [key: string]: boolean }>({ ...defaultExtended });

    useEffect(() => {
        setExtended({ ...defaultC });
    }, []);
    const onToggleExtend = (name: string) =>
        setExtended({
            ...extended,
            [name]: !extended[name],
        });

    const onBeforeNavigate = useCallback(
        (path: string) => {
            const queryURLValues = getQueryURLFacetValues(path);
            const qf = getQueryFilterFromQueryURL(facets, queryURLValues);
            if (qf) {
                actions.search(qf);
            }
        },
        [facets],
    );

    return (
        <div className="border-top">
            <div className="container m-y-double">
                <div data-am-linkcloud="">
                    <h2 className="h5 link-cloud-heading">{t("New and used cars at us", { ns: "footer" })}</h2>
                    <ul className="link-cloud">
                        {getObjectKey(cloudLinks).map((cloudLinkParent) => (
                            <li key={cloudLinkParent} className="link-cloud-item">
                                <h3 className="link-cloud-title">
                                    <Link
                                        onClick={() => onBeforeNavigate(`/sok/${urls.slugify(cloudLinkParent)}`)}
                                        to={`/sok/${urls.slugify(cloudLinkParent)}`}
                                        title={t("View this", { ns: "footer", name: cloudLinkParent })}
                                    >
                                        {cloudLinkParent}
                                    </Link>
                                </h3>
                                <ul className="link-cloud-nested is-extended">
                                    {getNestedLinks(extended[cloudLinkParent], cloudLinks, cloudLinkParent).map(
                                        (cloudLink) => (
                                            <li key={cloudLink} className="link-cloud-nested-item">
                                                <Link
                                                    onClick={() =>
                                                        onBeforeNavigate(
                                                            `/sok/${urls.slugify(cloudLinkParent)}/${urls.slugify(
                                                                cloudLink,
                                                            )}`,
                                                        )
                                                    }
                                                    to={`/sok/${urls.slugify(cloudLinkParent)}/${urls.slugify(
                                                        cloudLink,
                                                    )}`}
                                                    title={t("View this", {
                                                        ns: "footer",
                                                        name: `${cloudLinkParent} ${cloudLink}`,
                                                    })}
                                                    className="link-cloud-link"
                                                >
                                                    <div className="link-cloud-item-label">{cloudLink}</div>
                                                </Link>
                                            </li>
                                        ),
                                    )}
                                </ul>
                                {cloudLinks[cloudLinkParent as keyof typeof cloudLinks].length > 5 && (
                                    <div className="link-cloud-extend">
                                        <button
                                            className="link-cloud-extend-btn"
                                            onClick={() => onToggleExtend(cloudLinkParent)}
                                        >
                                            {extended[cloudLinkParent]
                                                ? t("Show less", { ns: "common" })
                                                : t("Show more", { ns: "common" })}
                                        </button>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div data-am-linkcloud="" className="m-t-double">
                    <div className="h5 link-cloud-heading">{t("Other brands", { ns: "footer" })}</div>
                    <ul className="link-cloud is-tight">
                        {Object.keys(cloudLinks)
                            .filter((key) => cloudLinks[key as CloudLinkKeyTypes].length === 0)
                            .map((cloudLink) => (
                                <li key={cloudLink} className="link-cloud-item">
                                    <Link
                                        onClick={() => onBeforeNavigate(`/sok/${urls.slugify(cloudLink)}`)}
                                        to={`/sok/${urls.slugify(cloudLink)}`}
                                        title={t("View this", { ns: "footer", name: cloudLink })}
                                        className="link-cloud-link"
                                    >
                                        <div className="link-cloud-item-label">{cloudLink}</div>
                                    </Link>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DefaultFooterLinkCloud;
