import React from "react";
import { useTranslation } from "react-i18next";

import Link from "../link";
import { getWordmarkLogotype } from "../../shared/logotype";
import { MarketCode } from "../../@types/MarketCode";

interface FooterInfoContainerProps {
    market: MarketCode;
}

const FooterInfoContainer = ({ market }: FooterInfoContainerProps) => {
    const { t } = useTranslation("navigation");

    return (
        <div className="info-container">
            <Link to="/" title={t("Go to home page", { ns: "navigation" })} className="l-inline-block valign-middle">
                {getWordmarkLogotype({
                    market,
                    className: "footer-logo",
                })}
            </Link>
        </div>
    );
};

export default FooterInfoContainer;
