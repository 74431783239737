﻿import { compose, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import reducers from "../reducers";

let serverState = {},
    dte = f => f;

try {
    serverState = window.__INITIAL__STATE__;
    delete window.__INITIAL__STATE__;

    if (process.env.NODE_ENV === "development") {
        dte = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;
    }
} catch (e) {
    serverState = {};
}

export default (initialState = null) =>
    createStore(
        reducers,
        initialState || serverState,
        compose(applyMiddleware(thunkMiddleware), dte),
    );
