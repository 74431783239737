import { settings } from "../helpers";
import { useTranslation } from "react-i18next";
import { getMarket } from "../i18n";
import { resolveArticlesRoute, resolveCarValuationRoute, resolveAssignmentRoute } from "../routes/utils";

const resolveCustomerServiceRoute = () => {
    const { marketCode } = getMarket();
    switch (marketCode) {
        case "NO":
            return "/kundeservice";
        default:
            return "/kundservice";
    }
};

export default () => {
    const { t } = useTranslation(["global", "navigation"]);
    const { marketCode } = getMarket();
    const showDealerLogin = marketCode === "SE";
    const showTestDrive = marketCode === "SE";
    const showLiveShopping = marketCode === "SE";

    return {
        homeLink: {
            path: "/",
            title: t("Go to home page", { ns: "navigation" }),
            displayName: t("Go to home page", { ns: "navigation" }),
        },
        mainNavigation: {
            ariaLabel: t("Main navigation", { ns: "navigation" }),
            links: [
                {
                    path: "/sok",
                    title: t("Search for a vehicle to buy", { ns: "header" }),
                    displayName: t("Search vehicle", { ns: "header" }),
                    hideInMainNav: true,
                },
                {
                    path: `/${resolveAssignmentRoute()}`,
                    title: t("Sell your car to a dealer", { ns: "header" }),
                    displayName: t("Sell car", { ns: "header" }),
                },
                {
                    path: `/${resolveCarValuationRoute()}`,
                    title: t("Value your car", { ns: "header" }),
                    displayName: t("Value car", { ns: "header" }),
                },
                ,
                {
                    path: `/${resolveArticlesRoute()}`,
                    title: t("Articles", { ns: "navigation" }),
                    displayName: t("Articles", { ns: "navigation" }),
                },
            ],
        },
        secondaryNavigation: {
            links: [
                ...[
                    showTestDrive
                        ? {
                              path: `/${resolveArticlesRoute()}/valja-bil`,
                              title: t("Test drives", { ns: "navigation" }),
                              displayName: t("Test drives", { ns: "navigation" }),
                          }
                        : undefined,
                ].filter((x) => !!x),
                ...[
                    showLiveShopping
                        ? {
                              path: "/artiklar/liveshopping",
                              title: t("Live shopping", { ns: "navigation" }),
                              displayName: t("Live shopping", { ns: "navigation" }),
                          }
                        : undefined,
                ].filter((x) => !!x),
                {
                    path: resolveCustomerServiceRoute(),
                    title: t("Contact and help", { ns: "navigation" }),
                    displayName: t("Customer support", { ns: "navigation" }),
                },
                {
                    path: settings.get("partnerSiteUri"),
                    title: t("SitePartner", { ns: "navigation" }),
                    displayName: t("SitePartner", { ns: "navigation" }),
                    b2b: true,
                    external: true,
                },
                ...[
                    showDealerLogin
                        ? {
                              path: settings.get("DealerUiUri"),
                              title: t("Dealer log in", { ns: "navigation" }),
                              displayName: t("Dealer log in", { ns: "navigation" }),
                              b2b: true,
                              external: true,
                          }
                        : undefined,
                ].filter((x) => !!x),
            ],
        },
    };
};
