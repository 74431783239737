﻿/* Other imports */
import "../stylesheets/default.scss";

import React, { Suspense } from "react";
import { hydrateRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { QueryClientProvider } from "react-query";
import { I18nextProvider } from "react-i18next";

import { Router, Switch, Route } from "react-router-dom";

import QueryClientSetup from "./QueryClientSetup";
import configureStore from "./shared/store";
import theme from "./styled-components/layout/theme";
import Fonts from "./styled-components/layout/fonts";
import GlobalStyle from "./styled-components/layout/global-style";

import { getAccessToken } from "./actions/auth";
import { init } from "./actions/event";
import { facets } from "./actions/search";
import { auth, pubsub, phonenumbers } from "./utils";

import App from "./components/app/app";
import { routes } from "./routes";

import history from "./history";
import routingSetup from "./routing-setup";
import { LiveShopping } from "./shared/tracking";
import { track } from "./actions/tracking";
import { get } from "./helpers/settings";
import getI18n, { getMarket } from "./i18n";
import SuspenseLoader from "./suspense-loader";
import SuspenseLoaderProvider from "./providers/SuspenseLoaderProvider";

const queryClient = QueryClientSetup(undefined, undefined, location.search, getMarket().marketCode);
let store = configureStore();

const ua = navigator.userAgent || navigator.vendor || window.opera || "";

const isTranslated = () =>
    !!document.querySelector(
        "html.translated-ltr, html.translated-rtl, ya-tr-span, *[_msttexthash], *[x-bergamot-translated]",
    );

const instagramOrFacebook = ["Facebook", "Instagram"].includes(ua);
if (!instagramOrFacebook && !isTranslated()) {
    const sentryDsn = get("sentryDsn");
    if (sentryDsn) {
        Sentry.init({
            dsn: sentryDsn,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.0,
            environment: process.env.NODE_ENV,
            beforeSend(event) {
                if (isTranslated()) return null;
                if (event?.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename === `<anonymous>`) return null;
                return event;
            },
            ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
            allowUrls: [/https?:\/\/(www\.)?wayke\.se\/build/, /https?:\/\/(www\.)?wayketech\.se\/build/],
        });
    }
}

window.addEventListener(
    "load",
    () => {
        if (window && window.googletag && window.googletag.cmd) {
            window.googletag.cmd.push(function () {
                window.googletag.pubads().enableSingleRequest();
                window.googletag.pubads().collapseEmptyDivs();
                window.googletag.pubads().setCentering(true);
                window.googletag.enableServices();
            });
        }

        let node = document.querySelector("#wayke-web-ui");
        if (node) {
            const i18n = getI18n(window.__WAYKE_MARKET__);
            const callback = () => {
                let { dispatch, getState } = store;

                routingSetup(dispatch);
                auth.createRefreshTokenHandler(dispatch, getState);
                getAccessToken()(dispatch, getState);

                facets()(dispatch, getState);

                pubsub.attach(window, dispatch);
                phonenumbers.initialize();

                init()(dispatch, getState);
                window.addEventListener("bambuser-liveshop-tracking-point", ({ detail }) => {
                    if (detail.data.interactionType) {
                        track(LiveShopping.LIVE_SHOPPING_INTERACTION, {
                            information: {
                                showId: detail?.data?.showId,
                                interactionType: detail?.data?.interactionType,
                                isLive: detail?.data?.timeline?.isLive,
                            },
                        })(dispatch, getState);
                    }
                });
            };

            const market = getMarket();
            const _routes = routes();
            hydrateRoot(
                node,
                <ThemeProvider theme={theme}>
                    <I18nextProvider i18n={i18n}>
                        <QueryClientProvider client={queryClient}>
                            <Provider store={store}>
                                <SuspenseLoaderProvider>
                                    <Router history={history}>
                                        <Fonts market={market.marketCode} />
                                        <GlobalStyle />
                                        <App callback={callback} market={market.marketCode}>
                                            <Suspense fallback={<SuspenseLoader />}>
                                                <Switch>
                                                    {Object.keys(_routes).map((key) => (
                                                        <Route key={key} path={key} {..._routes[key]} />
                                                    ))}
                                                </Switch>
                                            </Suspense>
                                        </App>
                                    </Router>
                                </SuspenseLoaderProvider>
                            </Provider>
                        </QueryClientProvider>
                    </I18nextProvider>
                </ThemeProvider>,
            );
        }
    },
    true,
);
