import { replaceRoute } from "./actions/routing";

const createRouteName = () => {
    const path = location.pathname || "/";
    let querystring = (location.search || "").replace(/^\?/, "");
    let hash = (location.hash || "").replace(/^\#/, "");

    if (querystring) querystring = `?${querystring}`;

    if (hash) hash = `#${hash}`;

    return path + querystring + hash;
};

const routingSetup = (dispatch) => {
    window.addEventListener("popstate", () => {
        replaceRoute(createRouteName())(dispatch);
    });
};

export default routingSetup;
