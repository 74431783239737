import { useEffect, useRef } from "react";
import { useSuspensLoader } from "./providers/SuspenseLoaderProvider";

const SuspenseLoader = () => {
    const { setLoading } = useSuspensLoader();
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setLoading(true);
        }, 1000);

        return () => {
            clearTimeout(timeoutRef.current);
            setLoading(false);
        };
    }, []);

    return null;
};

export default SuspenseLoader;
