import React from "react";

import { PortalElement, PortalNamespace } from "../../styled-components/components/Portal";
import { Root, RootMain } from "../../styled-components/components/Root";
import Header from "../../styled-components/components/Header";
import Footer from "./footer";
import Imbox from "./imbox";
import RenderClientSide from "../render/render-client-side";
import useHeaderVariant from "../../hooks/useHeaderVariant";
import { getWordmarkLogotype } from "../../shared/logotype";
import Middlewares from "./middlewares";
import { useSuspensLoader } from "../../providers/SuspenseLoaderProvider";
import { SrOnly } from "../../styled-components/components/SrOnly";
import { PageLoader } from "../../styled-components/components/PageLoader";
import getHeaderLinks from "../../shared/header-links";
import { useTranslation } from "react-i18next";

const App = ({ callback, children, market }) => {
    const headerLinks = getHeaderLinks();
    const headerVariant = useHeaderVariant();
    const { loading } = useSuspensLoader();
    const { t } = useTranslation();

    return (
        <>
            {loading && (
                <PageLoader>
                    <SrOnly>{t("loading", { ns: "other" })}</SrOnly>
                </PageLoader>
            )}
            <PortalElement id={PortalNamespace.DefaultPortal} />
            <Root ref={callback}>
                <Header
                    variant={headerVariant}
                    logo={getWordmarkLogotype({
                        market,
                    })}
                    {...headerLinks}
                />
                <Middlewares />
                <RootMain>{children}</RootMain>
                <Footer />
                <RenderClientSide>
                    <Imbox />
                </RenderClientSide>
            </Root>
        </>
    );
};

export default App;
