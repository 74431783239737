import { requestStatus, responseStatus } from "../shared/statuses";

import {
    REQ_FETCH_CONTENT_MODULE_LIST,
    RCV_FETCH_CONTENT_MODULE_LIST,
    REQ_FETCH_SELL_TO_DEALER_QUOTE_MODULE,
    RCV_FETCH_SELL_TO_DEALER_QUOTE_MODULE,
    REQUEST_FAILED,
} from "../actions/content-module";
import { ContentModule, QuoteModule } from "../@types/ContentModule";
import { Actions } from "../actions";
import { DocumentList } from "../@types/ElasticSearchResponse";

interface ContentModulesState {
    requestStatus: number;
    responseStatus: number;
    documentList?: DocumentList<ContentModule>;
    sellToDealerList?: DocumentList<QuoteModule>;
}

export const initialState: ContentModulesState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
};

const contentmodulesReducer = (state: ContentModulesState = initialState, action: Actions): ContentModulesState => {
    switch (action.type) {
        case REQ_FETCH_CONTENT_MODULE_LIST:
            return { ...state, requestStatus: requestStatus.FETCHING_LIST, responseStatus: responseStatus.PENDING };
        case RCV_FETCH_CONTENT_MODULE_LIST:
            return {
                ...state,
                documentList: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_FETCH_SELL_TO_DEALER_QUOTE_MODULE:
            return { ...state, requestStatus: requestStatus.FETCHING_LIST, responseStatus: responseStatus.PENDING };
        case RCV_FETCH_SELL_TO_DEALER_QUOTE_MODULE:
            return {
                ...state,
                sellToDealerList: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQUEST_FAILED:
            return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};

export default contentmodulesReducer;
