import { requestStatus, responseStatus } from "../shared/statuses";

import { RCV_ROUTING } from "../actions/routing";
import {
    REQ_ITEM,
    RCV_ITEM,
    REQ_ITEM_FACETS,
    RCV_ITEM_FACETS,
    REQ_RELATED_ITEMS,
    RCV_RELATED_ITEMS,
    REQ_CLEAR,
    REQUEST_FAILED,
} from "../actions/item";
import { Facet, DocumentList } from "../@types/ElasticSearchResponse";
import Item from "../@types/item";
import { Actions } from "../actions";

interface ItemState {
    requestStatus: number;
    responseStatus: number;
    facets: Facet[] | null;
    current: DocumentList<Item> | null;
    related: DocumentList<Item> | null;
    notFound: boolean;
}

const emptyList: Facet[] = [];
export const initialState: ItemState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    facets: emptyList,
    current: null,
    related: null,
    notFound: false,
};

const itemReducer = (state: ItemState = initialState, action: Actions): ItemState => {
    switch (action.type) {
        case RCV_ROUTING:
            return { ...state, notFound: false };
        case REQ_ITEM:
            return {
                ...state,
                current: null,
                related: null,
                notFound: false,
                requestStatus: requestStatus.FETCHING,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_ITEM:
            return {
                ...state,
                current: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_ITEM_FACETS:
            return state;
        case RCV_ITEM_FACETS:
            return { ...state, facets: action.response.facets };
        case REQ_RELATED_ITEMS:
            return { ...state, related: null };
        case RCV_RELATED_ITEMS:
            return { ...state, related: action.response.documentList };
        case REQ_CLEAR:
            return { ...state, current: null, related: null };
        case REQUEST_FAILED:
            if (action.action === REQ_RELATED_ITEMS) {
                return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
            }
            return {
                ...state,
                current: null,
                notFound: action.action === REQ_ITEM,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.FAILED,
            };
        default:
            return state;
    }
};

export default itemReducer;
