import { QueryClient } from "react-query";
import { Article } from "../../../../@types/ArticleV2";
import { ElasticSearchResponse } from "../../../../@types/ElasticSearchResponse";
import { ApiResponse } from "../../../../Http";
import { SSR_NEW } from "../../../../QueryClientSetup";
import { ARTICLES_QUERY } from "../../../../Data/Article/QueryKeys";
import { getLatestTestDriveArticleURLSearchParams } from "../utils";
import { MarketCode } from "../../../../@types/MarketCode";

const registerSsrArticlesStartPage = (queryClient: QueryClient, serverState: SSR_NEW, marketCode: MarketCode) => {
    const state = serverState.articles?.latestTestDriveArticle;

    const initialData: ApiResponse<ElasticSearchResponse<Article>> | undefined = state
        ? {
              response: state,
              successful: true,
          }
        : undefined;

    if (initialData) {
        const params = getLatestTestDriveArticleURLSearchParams(marketCode);
        queryClient.setQueryData([ARTICLES_QUERY, params.toString()], initialData);
    }

    return queryClient;
};

export default registerSsrArticlesStartPage;
