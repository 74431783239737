import { QueryClient } from "react-query";
import { FaqCategory } from "../../../@types/FaqCategory";
import { ElasticSearchResponse } from "../../../@types/ElasticSearchResponse";
import { ApiResponse } from "../../../Http";
import { SSR_OLD } from "../../../QueryClientSetup";
import { getFaqsURLSearchParams, getCategory } from "../utils";
import { FAQ_QUERY, FAQ_CATEGORIES_QUERY } from "./QueryKeys";
import { Faq } from "../../../@types/Faq";

const getQuery = (params: URLSearchParams) =>
    typeof Buffer !== "undefined" ? Buffer.from(params.toString()).toString("base64") : btoa(params.toString());

const registerSsrFaq = (queryClient: QueryClient, serverState: SSR_OLD, search: string) => {
    const categories = serverState.faq?.categories;
    const initialDataCategories: ApiResponse<ElasticSearchResponse<FaqCategory>> | undefined = categories
        ? {
              response: categories,
              successful: true,
          }
        : undefined;

    if (initialDataCategories) {
        queryClient.setQueryData(FAQ_CATEGORIES_QUERY, initialDataCategories);
    }

    const category = getCategory(categories?.documentList.documents, serverState.routing?.path);
    const params = getFaqsURLSearchParams(category, search);

    const faqs = serverState.faq?.faqs;
    const initialData: ApiResponse<ElasticSearchResponse<Faq>> | undefined = faqs
        ? {
              response: faqs,
              successful: true,
          }
        : undefined;

    if (initialData) {
        queryClient.setQueryData([FAQ_QUERY, getQuery(params)], initialData);
    }
    return queryClient;
};

export default registerSsrFaq;
