import { requestStatus, responseStatus } from "../shared/statuses";

import {
    REQ_RETAILER_LIST,
    RCV_RETAILER_LIST,
    REQ_RETAILER,
    RCV_RETAILER,
    REQ_RETAILER_SEARCH,
    RCV_RETAILER_SEARCH,
    REQ_RETAILER_VEHICLE_SEARCH,
    RCV_RETAILER_VEHICLE_SEARCH,
    REQ_CLEAR_RETAILER,
    REQUEST_FAILED,
} from "../actions/retailer";
import { Actions } from "../actions";
import { DocumentList } from "../@types/ElasticSearchResponse";
import { Retailer } from "../@types/Retailer";
import { Vehicle } from "../@types/Vehicle";

interface RetailersState {
    requestStatus: number;
    responseStatus: number;
    documentList: DocumentList<Retailer> | null;
    single: DocumentList<Retailer> | null;
    searchDocuments: DocumentList<Vehicle> | null;
    searchQueryFilter: string;
    notFound: boolean;
    searchBranchId: string;
}

export const initialState: RetailersState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    documentList: null,
    single: null,
    searchDocuments: null,
    searchQueryFilter: "",
    notFound: false,
    searchBranchId: "",
};

const retailersReducer = (state: RetailersState = initialState, action: Actions): RetailersState => {
    switch (action.type) {
        case REQ_RETAILER_LIST:
            return {
                ...state,
                documentList: null,
                requestStatus: requestStatus.FETCHING_LIST,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_RETAILER_LIST:
            return {
                ...state,
                documentList: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_RETAILER:
            return {
                ...state,
                single: null,
                requestStatus: requestStatus.FETCHING,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_RETAILER:
            return {
                ...state,
                single: action.response.documentList,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_RETAILER_SEARCH:
            return {
                ...state,
                documentList: null,
                requestStatus: requestStatus.FETCHING_LIST,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_RETAILER_SEARCH:
            return {
                ...state,
                documentList: action.response.documentList,
                searchQueryFilter: action.queryFilter,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_RETAILER_VEHICLE_SEARCH:
            return {
                ...state,
                searchDocuments: null,
                searchBranchId: action.branchId,
                requestStatus: requestStatus.FETCHING_LIST,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_RETAILER_VEHICLE_SEARCH:
            return {
                ...state,
                searchDocuments: action.response ? action.response.documentList : null,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_CLEAR_RETAILER:
            return {
                ...initialState,
                documentList: state.documentList,
            };
        case REQUEST_FAILED:
            return {
                ...state,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.FAILED,
            };
        default:
            return state;
    }
};

export default retailersReducer;
