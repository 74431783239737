import React from "react";
import { useTranslation } from "react-i18next";
import { getMarket } from "../../i18n";

const FooterSocialMedia = () => {
    const { t } = useTranslation("footer");
    const marketCode = getMarket().marketCode;
    const currentMarket = marketCode === "NO" ? "drive" : "wayke";

    const socialMediaLinks: {
        [key: string]: {
            linkedin?: string;
            instagram?: string;
            facebook?: string;
            youtube?: string;
        };
    } = {
        wayke: {
            linkedin: "https://www.linkedin.com/company/wayke/",
            instagram: "https://www.instagram.com/wayke.se/",
            facebook: "https://www.facebook.com/waykese",
            youtube: "https://www.youtube.com/channel/UCf8UIEXyca2ubmYg3o-xIkg",
        },
        drive: {
            linkedin: "https://www.linkedin.com/company/drive.no/",
            instagram: "https://www.instagram.com/drive_mobility/",
            facebook: "https://www.facebook.com/profile.php?id=100091112151742",
        },
    };

    return (
        <div className="col-sm-6 col-md-3 m-b">
            <div className="footer-heading">{t("Social media", { ns: "footer" })}</div>
            <div className="m-t-half">
                {socialMediaLinks[currentMarket].linkedin && (
                    <a
                        href={socialMediaLinks[currentMarket].linkedin}
                        title={t("Visit us on LinkedIn", { ns: "footer" })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-action"
                    >
                        <div data-am-icon="primary hover">
                            <i className="icon-linkedin"></i>
                        </div>
                    </a>
                )}
                {socialMediaLinks[currentMarket].instagram && (
                    <a
                        href={socialMediaLinks[currentMarket].instagram}
                        title={t("Visit us on Instagram", { ns: "footer" })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-action"
                    >
                        <div data-am-icon="primary hover">
                            <i className="icon-instagram"></i>
                        </div>
                    </a>
                )}
                {socialMediaLinks[currentMarket].facebook && (
                    <a
                        href={socialMediaLinks[currentMarket].facebook}
                        title={t("Visit us on Facebook", { ns: "footer" })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-action"
                    >
                        <div data-am-icon="primary hover">
                            <i className="icon-facebook"></i>
                        </div>
                    </a>
                )}
                {socialMediaLinks[currentMarket].youtube && (
                    <a
                        href={socialMediaLinks[currentMarket].youtube}
                        title={t("Visit us on YouTube", { ns: "footer" })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-action"
                    >
                        <div data-am-icon="primary hover">
                            <i className="icon-youtube"></i>
                        </div>
                    </a>
                )}
            </div>
        </div>
    );
};

export default FooterSocialMedia;
