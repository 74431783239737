import { QueryClient } from "react-query";

import registerSsrArticles from "../Data/Article/registerSsrArticles";
import registerSsrArticle from "../Data/Article/registerSsrArticle";
import { Article, ArticleAuthor } from "../@types/ArticleV2";
import { ArticleCategory } from "../@types/ArticleCategory";
import registerSsrFaq from "../routes/customer-service/Data/registerSsrFaq";
import { FaqCategory } from "../@types/FaqCategory";
import { Faq } from "../@types/Faq";
import { LiveShopping } from "../@types/LiveShopping";
import { ElasticSearchResponse } from "../@types/ElasticSearchResponse";
import registerSsrArticlesStartPage from "../routes/startpage/ArticlesSection/Data/registerSsrArticlesStartPage";
import registerSsrLiveShopping from "../Data/Liveshopping/registerSsrLiveShopping";
import { Seo } from "../@types/Seo";
import registerSsrSeo from "../routes/customer-service/Data/registerSsrSeo";
import { MarketCode } from "../@types/MarketCode";
import registerSsrArticlesSearch from "../Data/Article/registerSsrArticlesSearch";
import registerSsrAuthor from "../Data/Author/registerSsrAuthor";

export interface SSR_OLD {
    routing?: {
        path: string;
    };
    articles?: {
        categories?: ArticleCategory[];
        result?: ElasticSearchResponse<Article>;
        single?: ElasticSearchResponse<Article>;
        latestTestDriveArticle?: ElasticSearchResponse<Article>;
    };
    faq?: {
        categories?: ElasticSearchResponse<FaqCategory>;
        faqs: ElasticSearchResponse<Faq>;
    };
}

export interface SSR_NEW {
    liveShopping?: {
        featuredLiveShopping?: ElasticSearchResponse<LiveShopping>;
        liveShoppingResult?: ElasticSearchResponse<LiveShopping>;
        liveShoppingResultBySlug?: ElasticSearchResponse<LiveShopping>;
    };
    articles?: {
        categories?: ArticleCategory[];
        result?: ElasticSearchResponse<Article>;
        singleResult?: ElasticSearchResponse<Article>;
        latestTestDriveArticle?: ElasticSearchResponse<Article>;
    };
    seo?: {
        search: Seo;
    };
    author?: {
        result?: ElasticSearchResponse<ArticleAuthor>;
    };
}

let serverStateOld: SSR_OLD = {};
try {
    serverStateOld = { ...window.__INITIAL__STATE__ } as SSR_OLD;
} catch (e) {
    serverStateOld = {};
}

let serverState: SSR_NEW = {};
try {
    serverState = { ...window.__WAYKE_SSR__ } as SSR_NEW;
} catch (e) {
    serverState = {};
}

const QueryClientSetup = (
    ssr: SSR_NEW = serverState,
    initialState: SSR_OLD = serverStateOld,
    search: string,
    marketCode: MarketCode,
) => {
    const queryClient = new QueryClient();

    registerSsrArticle(queryClient, ssr);
    registerSsrArticles(queryClient, ssr, initialState, search);
    registerSsrAuthor(queryClient, ssr);
    registerSsrFaq(queryClient, initialState, search);
    registerSsrArticlesStartPage(queryClient, ssr, marketCode);
    registerSsrArticlesSearch(queryClient, ssr, search);
    registerSsrLiveShopping(queryClient, ssr);
    registerSsrSeo(queryClient, ssr);

    return queryClient;
};

export default QueryClientSetup;
