import { requestStatus, responseStatus } from "../shared/statuses";

import {
    RESET_DEFAULT_FINANCE_INFORMATION,
    REQ_GET_DEFAULT_FINANCE_INFORMATION,
    RCV_GET_DEFAULT_FINANCE_INFORMATION,
    REQUEST_FAILED,
} from "../actions/default-financial-information";
import { Actions } from "../actions";
import { FinancialInformation } from "../@types/finance";

interface DefaultFinancialInformationState {
    requestStatus: number;
    responseStatus: number;
    default: FinancialInformation | null;
}

export const initialState: DefaultFinancialInformationState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    default: null,
};

const defaultFinancialInformationReducer = (
    state: DefaultFinancialInformationState = initialState,
    action: Actions,
): DefaultFinancialInformationState => {
    switch (action.type) {
        case RESET_DEFAULT_FINANCE_INFORMATION:
            return { default: null, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.OK };
        case REQ_GET_DEFAULT_FINANCE_INFORMATION:
            return { default: null, requestStatus: requestStatus.FETCHING, responseStatus: responseStatus.PENDING };
        case RCV_GET_DEFAULT_FINANCE_INFORMATION:
            return {
                default: action.response,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQUEST_FAILED:
            return { default: null, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};

export default defaultFinancialInformationReducer;
