import { requestStatus, responseStatus } from "../shared/statuses";

import {
    REQ_VEHICLE_LOOKUP,
    RCV_VEHICLE_LOOKUP,
    REQ_VEHICLE_OPTIONS,
    RCV_VEHICLE_OPTIONS,
    REQ_CLEAR,
    REQUEST_FAILED,
} from "../actions/vehicle";
import { Vehicle } from "../@types/Vehicle";
import { Facet } from "../@types/ElasticSearchResponse";
import { Actions } from "../actions";

interface VehiclesState {
    requestStatus: number;
    responseStatus: number;
    current: Vehicle | null;
    vehicle: Vehicle | null;
    options: Facet[] | null;
}

export const initialState: VehiclesState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    current: null,
    vehicle: null,
    options: null,
};

const vehiclesReducer = (state = initialState, action: Actions): VehiclesState => {
    switch (action.type) {
        case REQ_VEHICLE_LOOKUP:
            return {
                ...state,
                current: null,
                requestStatus: requestStatus.FETCHING,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_VEHICLE_LOOKUP:
            return {
                ...state,
                current: action.response,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_VEHICLE_OPTIONS:
            return {
                ...state,
                options: null,
                requestStatus: requestStatus.FETCHING_LIST,
                responseStatus: responseStatus.PENDING,
            };
        case RCV_VEHICLE_OPTIONS:
            return {
                ...state,
                options: action.response.facets,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
            };
        case REQ_CLEAR:
            return { ...initialState };
        case REQUEST_FAILED:
            return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};

export default vehiclesReducer;
