import { SET_LIVE_SHOPPING_REFERENCE, CLEAR_LIVE_SHOPPING_REFERENCE } from "../actions/liveshopping";
import { Actions } from "../actions";

interface LiveshoppingReference {
    reference?: string;
}

export const initialState: LiveshoppingReference = {};

const liveshoppingReducer = (state: LiveshoppingReference = initialState, action: Actions): LiveshoppingReference => {
    switch (action.type) {
        case SET_LIVE_SHOPPING_REFERENCE:
            return { ...state, reference: action.reference };
        case CLEAR_LIVE_SHOPPING_REFERENCE:
            return { ...state, reference: undefined };
        default:
            return state;
    }
};

export default liveshoppingReducer;
