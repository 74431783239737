import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction, Dispatch } from "redux";

import { toggleMenu } from "../../actions/event";
import { RCV_ROUTING } from "../../actions/routing";
import { RootState } from "../../reducers";

const useRedirector = (dispatch: Dispatch<AnyAction>) => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        response?: {
            revalidate?: boolean;
        };
    };
    const showMobileMenu = useSelector<RootState, boolean>((s) => s.events.showMobileMenu);

    useEffect(() => {
        if (action && action.type === RCV_ROUTING && showMobileMenu) {
            toggleMenu()(dispatch);
        }
    }, [action]);
};

const useRedirectors = () => {
    const dispatch = useDispatch();

    useRedirector(dispatch);
};

export default useRedirectors;
