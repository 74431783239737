import { requestStatus, responseStatus } from "../shared/statuses";
import {
    REQ_SHARED_SUBSCRIPTION_NAMESPACE,
    RCV_SHARED_SUBSCRIPTION_NAMESPACE,
    REQUEST_FAILED,
} from "../actions/ad-subscription-namespaces";
import { Actions } from "../actions";
import { SubscriptionAdvertisement } from "../@types/AdvertismentSubscription";

interface SharedUserContentState {
    requestStatus: number;
    responseStatus: number;
    sharedNamespaceAdvertisements: SubscriptionAdvertisement[];
}

export const initialState: SharedUserContentState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    sharedNamespaceAdvertisements: [],
};

export default (state = initialState, action: Actions) => {
    switch (action.type) {
        case REQ_SHARED_SUBSCRIPTION_NAMESPACE:
            return { ...state, requestStatus: requestStatus.FETCHING_LIST, responseStatus: responseStatus.PENDING };
        case RCV_SHARED_SUBSCRIPTION_NAMESPACE:
            return {
                ...state,
                requestStatus: requestStatus.WAITING,
                responseStatus: responseStatus.OK,
                sharedNamespaceAdvertisements: action.response,
            };
        case REQUEST_FAILED:
            if (action.action !== REQ_SHARED_SUBSCRIPTION_NAMESPACE) return state;
            return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};
