import { requestStatus, responseStatus } from "../shared/statuses";

import { REQ_VIMEO_THUMBNAIL, RCV_VIMEO_THUMBNAIL, REQ_FAILED } from "../actions/vimeo";
import { Actions } from "../actions";

interface ViemoState {
    requestStatus: number;
    responseStatus: number;
    thumbnails: { [key: string]: string | undefined };
}

export const initialState: ViemoState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
    thumbnails: {},
};

let thumbnails = null;
const vimeoReducer = (state = initialState, action: Actions): ViemoState => {
    switch (action.type) {
        case REQ_VIMEO_THUMBNAIL:
            thumbnails = { ...state.thumbnails };
            thumbnails[action.id] = "";
            return { ...state, requestStatus: requestStatus.FETCHING, responseStatus: responseStatus.PENDING };
        case RCV_VIMEO_THUMBNAIL:
            thumbnails = { ...state.thumbnails };
            thumbnails[action.id] = action.response.thumbnail_url;
            return { ...state, thumbnails, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.OK };
        case REQ_FAILED:
            return { ...state, requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};

export default vimeoReducer;
