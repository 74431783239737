import { requestStatus, responseStatus } from "../shared/statuses";

import {
    REQ_AD_SUBSCRIPTION_LIST,
    RCV_AD_SUBSCRIPTION_LIST,
    REQ_CREATE_AD_SUBSCRIPTION,
    RCV_CREATE_AD_SUBSCRIPTION,
    REQ_REMOVE_AD_SUBSCRIPTION,
    RCV_REMOVE_AD_SUBSCRIPTION,
    REQUEST_FAILED as AD_REQUEST_FAILED,
} from "../actions/ad-subscription";

import {
    REQ_SUBSCRIPTION_NAMESPACE_LIST,
    RCV_SUBSCRIPTION_NAMESPACE_LIST,
    REQ_CREATE_SUBSCRIPTION_NAMESPACE,
    RCV_CREATE_SUBSCRIPTION_NAMESPACE,
    REQ_UPDATE_SUBSCRIPTION_NAMESPACE,
    RCV_UPDATE_SUBSCRIPTION_NAMESPACE,
    REQUEST_FAILED as NS_REQUEST_FAILED,
} from "../actions/ad-subscription-namespaces";

import {
    REQ_SEARCH_SUBSCRIPTION_LIST,
    RCV_SEARCH_SUBSCRIPTION_LIST,
    REQ_SET_SEARCH_SUBSCRIPTION_ITEM,
    REQ_CREATE_SEARCH_SUBSCRIPTION,
    RCV_CREATE_SEARCH_SUBSCRIPTION,
    REQ_UPDATE_SEARCH_SUBSCRIPTION,
    RCV_UPDATE_SEARCH_SUBSCRIPTION,
    REQ_REMOVE_SEARCH_SUBSCRIPTION,
    RCV_REMOVE_SEARCH_SUBSCRIPTION,
    REQUEST_FAILED as SEARCH_REQUEST_FAILED,
} from "../actions/search-subscription";
import { Actions } from "../actions";
import {
    AdvertisementNamespaceSubscription,
    SubscriptionAdvertisement,
    SubscriptionSearch,
    SubscriptionSearchItem,
} from "../@types/AdvertismentSubscription";

interface SubscriptionStateSearch {
    requestStatus: number;
    responseStatus: number;
    synced: boolean;
    list: SubscriptionSearchItem[];
}

interface SubscriptionStateAdvertisement {
    requestStatus: number;
    responseStatus: number;
    synced: boolean;
    list: SubscriptionAdvertisement[];
}

interface SubscriptionStateNamespaces {
    requestStatus: number;
    responseStatus: number;
    list: AdvertisementNamespaceSubscription[];
}

interface SubscriptionsState {
    search: SubscriptionStateSearch;
    advertisement: SubscriptionStateAdvertisement;
    namespaces: SubscriptionStateNamespaces;
}

export const initialState: SubscriptionsState = {
    search: {
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
        list: [],
        active: null,
        synced: false,
    },
    advertisement: {
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
        list: [],
        synced: false,
    },
    namespaces: {
        requestStatus: requestStatus.WAITING,
        responseStatus: responseStatus.OK,
        list: [],
    },
};

const subscriptionsReducer = (state: SubscriptionsState = initialState, action: Actions): SubscriptionsState => {
    let listSearch: SubscriptionSearch[] | null = null;
    let listAdvertisement: SubscriptionAdvertisement[] | null = null;
    let listNamespaces: AdvertisementNamespaceSubscription[] | null = null;

    let index = -1;
    let sub = null;

    switch (action.type) {
        case REQ_AD_SUBSCRIPTION_LIST:
            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    requestStatus: requestStatus.FETCHING_LIST,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_AD_SUBSCRIPTION_LIST:
            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    list: action.response,
                    synced: true,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case REQ_CREATE_AD_SUBSCRIPTION:
            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    requestStatus: requestStatus.CREATING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_CREATE_AD_SUBSCRIPTION:
            listAdvertisement = state.advertisement.list.slice();
            listAdvertisement.push(action.response);

            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    list: listAdvertisement,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case REQ_REMOVE_AD_SUBSCRIPTION:
            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    requestStatus: requestStatus.REMOVING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_REMOVE_AD_SUBSCRIPTION:
            listAdvertisement = state.advertisement.list.slice();
            index = listAdvertisement.findIndex((s) => s.id === action.response.id);

            if (index >= 0) {
                listAdvertisement.splice(index, 1);
            }

            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    list: listAdvertisement,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case AD_REQUEST_FAILED:
            return {
                ...state,
                advertisement: {
                    ...state.advertisement,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.FAILED,
                },
            };
        case REQ_SEARCH_SUBSCRIPTION_LIST:
            return {
                ...state,
                search: {
                    ...state.search,
                    requestStatus: requestStatus.FETCHING_LIST,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_SEARCH_SUBSCRIPTION_LIST:
            return {
                ...state,
                search: {
                    ...state.search,
                    list: action.response,
                    synced: true,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case REQ_SET_SEARCH_SUBSCRIPTION_ITEM: {
            index = state.search.list.findIndex((x) => x.id === action.subscription?.id);
            if (index > -1 && action.subscription) {
                const updatedList = state.search.list.slice();
                updatedList[index] = action.subscription;
                return {
                    ...state,
                    search: {
                        ...state.search,
                        list: updatedList,
                    },
                };
            }
            return { ...state };
        }

        case REQ_CREATE_SEARCH_SUBSCRIPTION:
            return {
                ...state,
                search: {
                    ...state.search,
                    requestStatus: requestStatus.CREATING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_CREATE_SEARCH_SUBSCRIPTION:
            listSearch = state.search.list.slice();
            sub = action.sub ? action.sub : {};
            listSearch.push({
                ...sub,
                ...action.response,
            });
            return {
                ...state,
                search: {
                    ...state.search,
                    list: listSearch,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case REQ_UPDATE_SEARCH_SUBSCRIPTION:
            return {
                ...state,
                search: {
                    ...state.search,
                    requestStatus: requestStatus.UPDATING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_UPDATE_SEARCH_SUBSCRIPTION:
            listSearch = state.search.list.slice();

            sub = action.sub ? action.sub : {};

            for (let i = 0; i < listSearch.length; i++) {
                if (listSearch[i].id === action.response.id) {
                    listSearch[i] = {
                        ...action.response,
                        ...sub,
                    };
                }
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    list: listSearch,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case REQ_REMOVE_SEARCH_SUBSCRIPTION:
            return {
                ...state,
                search: {
                    ...state.search,
                    requestStatus: requestStatus.REMOVING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_REMOVE_SEARCH_SUBSCRIPTION:
            listSearch = state.search.list.slice();
            index = listSearch.findIndex((s) => s.id === action.response.id);

            if (index >= 0) {
                listSearch.splice(index, 1);
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    list: listSearch,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                },
            };
        case SEARCH_REQUEST_FAILED:
            return {
                ...state,
                search: {
                    ...state.search,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.FAILED,
                },
            };

        case REQ_SUBSCRIPTION_NAMESPACE_LIST:
            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.FETCHING_LIST,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_SUBSCRIPTION_NAMESPACE_LIST:
            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                    list: action.response,
                },
            };
        case REQ_CREATE_SUBSCRIPTION_NAMESPACE:
            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.CREATING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_CREATE_SUBSCRIPTION_NAMESPACE:
            listNamespaces = state.namespaces.list.slice();
            listNamespaces.push(action.response);

            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                    list: listNamespaces,
                },
            };
        case REQ_UPDATE_SUBSCRIPTION_NAMESPACE:
            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.UPDATING,
                    responseStatus: responseStatus.PENDING,
                },
            };
        case RCV_UPDATE_SUBSCRIPTION_NAMESPACE:
            listNamespaces = state.namespaces.list.slice();

            for (let i = 0; i < listNamespaces.length; i++) {
                if (listNamespaces[i].id === action.response.id) {
                    listNamespaces[i] = action.response;
                }
            }

            let adList = state.advertisement.list.slice();
            adList = adList.map((x) => {
                if (x.namespace && action.response.id === x.namespace.id) {
                    return {
                        ...x,
                        namespace: action.response,
                    };
                }
                return x;
            });

            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.OK,
                    list: listNamespaces,
                },
                advertisement: {
                    ...state.advertisement,
                    list: adList,
                },
            };
        case NS_REQUEST_FAILED:
            return {
                ...state,
                namespaces: {
                    ...state.namespaces,
                    requestStatus: requestStatus.WAITING,
                    responseStatus: responseStatus.FAILED,
                },
            };
        default:
            return state;
    }
};

export default subscriptionsReducer;
