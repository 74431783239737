import { QueryClient } from "react-query";
import { SSR_NEW } from "../../../QueryClientSetup";
import { SEO_SSR_SEARCH_QUERY } from "./QueryKeys";

const registerSsrSeo = (queryClient: QueryClient, serverState: SSR_NEW) => {
    queryClient.setQueryData(SEO_SSR_SEARCH_QUERY, serverState.seo?.search);
    return queryClient;
};

export default registerSsrSeo;
