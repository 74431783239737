import { requestStatus, responseStatus } from "../shared/statuses";

import { REQ_CREATE_LEAD, RCV_CREATE_LEAD, REQUEST_FAILED } from "../actions/lead";
import { Actions } from "../actions";

interface LeadState {
    requestStatus: number;
    responseStatus: number;
}

export const initialState: LeadState = {
    requestStatus: requestStatus.WAITING,
    responseStatus: responseStatus.OK,
};

const leadReducer = (state: LeadState = initialState, action: Actions): LeadState => {
    switch (action.type) {
        case REQ_CREATE_LEAD:
            return { requestStatus: requestStatus.CREATING, responseStatus: responseStatus.PENDING };
        case RCV_CREATE_LEAD:
            return { requestStatus: requestStatus.WAITING, responseStatus: responseStatus.OK };
        case REQUEST_FAILED:
            return { requestStatus: requestStatus.WAITING, responseStatus: responseStatus.FAILED };
        default:
            return state;
    }
};

export default leadReducer;
