import React from "react";

import FooterSubLink from "./footer-sub-link";

interface FooterLinkProps {
    id: string;
    link: Record<string, string>;
}

const FooterLink = ({ id, link }: FooterLinkProps) => (
    <div className="col-sm-6 col-md-3 m-b">
        <div className="footer-heading">{id}</div>
        <ul data-am-list="">
            {link &&
                !!Object.keys(link).length &&
                Object.keys(link).map((subLvl) => <FooterSubLink key={subLvl} id={subLvl} link={link[subLvl]} />)}
        </ul>
    </div>
);

export default FooterLink;
