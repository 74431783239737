import styled from "styled-components";

export const Root = styled.div.withConfig({ componentId: 'sc-1uv3l2p-0' })`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const RootMain = styled.main.withConfig({ componentId: 'sc-1uv3l2p-1' })`
    flex: 1 0 auto;
    overflow-x: hidden;
`;
