import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";

import { SubscriptionSearch } from "../../@types/AdvertismentSubscription";
import { ElasticSearchResponse } from "../../@types/ElasticSearchResponse";
import { ElasticVehicle } from "../../@types/ElasticVehicle";
import { Token } from "../../@types/Token";
import { RCV_ACCESS_TKN } from "../../actions/auth";
import { EVENT_HIDE_CHAT, EVENT_MESSAGE_PIPE, EVENT_SHOW_CHAT } from "../../actions/event";
import { REQ_CLOSE_FOLDOUT, REQ_OPEN_FILTER, REQ_OPEN_FOLDOUT } from "../../actions/filters";
import { pushRoute } from "../../actions/routing";
import { RCV_SEARCH, REQ_SEARCH, search } from "../../actions/search";
import { RCV_CREATE_SEARCH_SUBSCRIPTION } from "../../actions/search-subscription";
import {
    beginSearchEvent,
    trackAddSubscription,
    trackConversionEvent,
    trackRegistrationEvent,
    trackRoutingEvent,
    trackSearchEvent,
} from "../../actions/tracking";
import QueryFilter from "../../helpers/query-filter";
import usePrevious from "../../hooks/usePrevious";
import { RootState } from "../../reducers";
import eventContext, { getPageTypeFromPath } from "../../shared/event-context";
import { conversionEvents } from "../../shared/tracking";
import { getFacetList, getSearchDocument } from "../../utils/reselectors";
import { resetViewport } from "../../utils/ts/viewport";

const queryFilterWithout = (filterQuery?: string) =>
    new QueryFilter(filterQuery).removeAll(QueryFilter.OperationalParams);

interface IImbox {
    push(actions: string[]): void;
}

interface IImboxWindow extends Window {
    _imbox: IImbox;
}

declare const window: IImboxWindow;

interface LastPipeMessage {
    origin: string;
    event: string;
}

const useImboxTracker = (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const action = useSelector<RootState>((s) => s.action) as { type: string; message?: LastPipeMessage };
    const document = useSelector(getSearchDocument);

    useEffect(() => {
        if (action.message?.event === conversionEvents.CONVERSION_CHAT_STARTED && document) {
            trackConversionEvent(document, action.message.event, {
                origin: action.message.origin,
            })(dispatch, getState);
        }
    }, [action]);
};

const useHideChat = () => {
    const action = useSelector<RootState>((s) => s.action) as { type: string };
    useEffect(() => {
        if (
            !process.env.SSR &&
            [REQ_OPEN_FOLDOUT, REQ_OPEN_FILTER, EVENT_HIDE_CHAT].includes(action?.type) &&
            window._imbox
        ) {
            window._imbox.push(["hideChatButton"]);
        }
    }, [action]);
};

const useShowChat = () => {
    const action = useSelector<RootState>((s) => s.action) as { type: string };
    useEffect(() => {
        if (!process.env.SSR && [REQ_CLOSE_FOLDOUT, EVENT_SHOW_CHAT].includes(action?.type) && window._imbox) {
            window._imbox.push(["showChatButton"]);
        }
    }, [action]);
};

const usePipeDisplayVehicleRedirect = () => {
    const { push } = useHistory();
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        message?: { event?: string; id: string; slug?: string };
    };

    useEffect(() => {
        if (action.type === EVENT_MESSAGE_PIPE && action.message?.event === "display-vehicle" && action.message.id) {
            push(`/objekt/${action.message.id}/${action.message.slug || "-"}`, null);
        }
    }, [action]);
};

const usePipeSearchVehiclesRedirect = (dispatch: Dispatch<AnyAction>) => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        message?: { event?: string; params: any };
    };
    const facets = useSelector(getFacetList);

    useEffect(() => {
        if (
            action.type === EVENT_MESSAGE_PIPE &&
            action.message?.event === "search-vehicles" &&
            action.message.params &&
            facets
        ) {
            const { params } = action.message;
            let queryFilter = QueryFilter.create();

            Object.keys(params).forEach((key) => {
                const facet = facets?.find((x) => x.id === key);

                if (!facet) return;

                if (facet.isRangeFacet && Array.isArray(params[key])) {
                    const min = params[key][0] || undefined;
                    const max = params[key].length > 1 ? params[key][1] : undefined;

                    queryFilter = queryFilter.addRange(key, min, max);
                } else if (facet.isLocationFacet) {
                    queryFilter = queryFilter.addSpatial(params[key]);
                } else {
                    const values: any[] = Array.isArray(params[key]) ? params[key] : [params[key]];
                    values.forEach((value) => (queryFilter = queryFilter.add(key, value)));
                }
            });

            pushRoute(`/sok${queryFilter.toQueryURL()}`)(dispatch);
            search(queryFilter)(dispatch);
        }
    }, [action]);
};

const useRegistration = (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        response?: { accessToken?: string };
    };

    useEffect(() => {
        if (action.type === RCV_ACCESS_TKN && action.response?.accessToken) {
            const isNew = jwtDecode<Token>(action.response.accessToken)["is-new-user"];
            if (isNew) {
                trackRegistrationEvent()(dispatch, getState);
            }
        }
    }, [action]);
};

const useRequestSearch = () => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        searchQueryParams?: string;
    };

    useEffect(() => {
        if (action.type === REQ_SEARCH) {
            const queryFilter = queryFilterWithout(action.searchQueryParams);
            if (queryFilter) {
                beginSearchEvent(queryFilter.toString());
            }
        }
    }, [action]);
};

const useSearch = (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        searchQueryParams: string;
        response: ElasticSearchResponse<ElasticVehicle>;
    };

    useEffect(() => {
        if (action.type === RCV_SEARCH) {
            const qf = queryFilterWithout(action.searchQueryParams);
            const queryFilter = qf.hasQuery() || qf.hasFilters() ? qf : null;
            if (queryFilter) {
                trackSearchEvent(queryFilter.toString(), action.response)(dispatch, getState);
            }
        }
    }, [action]);
};

const useRouting = (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const pathname = useLocation().pathname;
    const previousPathname = usePrevious(pathname);

    useEffect(() => {
        if (pathname !== previousPathname) {
            if (!process.env.SSR) {
                resetViewport();
            }
            trackRoutingEvent({
                path: pathname,
                from: previousPathname,
            })(dispatch, getState);
            eventContext.pageType = getPageTypeFromPath(location.pathname);
        }
    }, [pathname]);
};

const useSearchSubscription = (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    const action = useSelector<RootState>((s) => s.action) as {
        type: string;
        response: SubscriptionSearch;
    };

    useEffect(() => {
        if (action.type === RCV_CREATE_SEARCH_SUBSCRIPTION) {
            const response = getState().search ? action.response : null;
            if (response) {
                if (response?.referenceId) {
                    // Track list page subscription
                    // currently using callback search
                    trackAddSubscription(response, undefined)(dispatch, getState);
                } else {
                    trackAddSubscription(response, getState().search)(dispatch, getState);
                }
            }
        }
    }, [action]);
};

const useUiTracker = () => {
    const dispatch = useDispatch();
    const getState = useStore().getState as () => RootState;

    useRouting(dispatch, getState);
    useImboxTracker(dispatch, getState);
    useHideChat();
    useShowChat();
    usePipeDisplayVehicleRedirect();
    usePipeSearchVehiclesRedirect(dispatch);
    useRegistration(dispatch, getState);
    useRequestSearch();
    useSearch(dispatch, getState);
    useSearchSubscription(dispatch, getState);
};

export default useUiTracker;
