import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../reducers";
import { isGuaranteePackageLandingPage } from "../selectors/content-page";
import { getCurrentListPageFromQueryUrl, isListPage } from "../selectors/list";

const useHeaderVariant = () => {
    const { pathname } = useLocation();
    const pageIsGuaranteePackageLandingPage = useSelector<RootState, boolean>((s) =>
        isGuaranteePackageLandingPage(s, pathname),
    );
    const listPage = useSelector<RootState, boolean>((s) => isListPage(s, pathname));
    const searchWithList = useSelector<RootState, boolean>((s) => !!getCurrentListPageFromQueryUrl(s));

    if (
        pageIsGuaranteePackageLandingPage ||
        listPage ||
        searchWithList ||
        pathname === "/" ||
        pathname === "/salj-till-handlare" ||
        pathname === "/selg-til-forhandler" ||
        pathname === "/vardera-bil" ||
        pathname === "/verdivurdere-bil"
    ) {
        return "transparent";
    }

    return undefined;
};

export default useHeaderVariant;
