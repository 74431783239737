import { combineReducers } from "redux";
import action from "./action";
import events from "./events";
import auth from "./auth";
import search from "./search";
import item from "./item";
import filters from "./filters";
import retailers from "./retailers";
import settings from "./settings";
import conversations from "./conversations";
import messages from "./messages";
import media from "./media";
import assignments from "./assignments";
import assignmentDrafts from "./assignmentDrafts";
import geo from "./geo";
import history from "./history";
import subscriptions from "./subscriptions";
import contentmodules from "./content-modules";
import contentpages from "./contentpages";
import insurances from "./insurances";
import brandings from "./brandings";
import faq from "./faq";
import vehicles from "./vehicles";
import pubsub from "./pubsub";
import safetyAddons from "./safety-addons";
import creditApplication from "./credit-application";
import lead from "./lead";
import vimeo from "./vimeo";
import list from "./list";
import sharedUserContent from "./shared-user-content";
import defaultFinancialInformation from "./default-financial-information";
import currentFinancialInformation from "./current-financial-information";
import missingItem from "./missing-item";
import routing from "./routing";
import liveshopping from "./liveshopping";

const rootReducer = combineReducers({
    action,
    routing,
    events,
    auth,
    settings,
    search,
    item,
    filters,
    retailers,
    conversations,
    messages,
    media,
    assignments,
    geo,
    faq,
    history,
    subscriptions,
    contentmodules,
    contentpages,
    insurances,
    brandings,
    vehicles,
    pubsub,
    safetyAddons,
    creditApplication,
    lead,
    vimeo,
    list,
    sharedUserContent,
    defaultFinancialInformation,
    currentFinancialInformation,
    assignmentDrafts,
    missingItem,
    liveshopping,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
