import styled, { css } from "styled-components";
import { Link as RRDLink } from "react-router-dom";

import { size } from "../../layout/helpers";
import { ButtonReset } from "../../layout/reset";
import { RootMain } from "../Root";
import { Icon } from "../Icon";

interface WrapperProps {
    $variant?: "transparent";
    $menuOpen?: boolean;
}

export const Wrapper = styled.header.withConfig({ componentId: 'sc-drvkj-0' })<WrapperProps>`
    --header-gap: ${size(3)};
    --_td: 400ms; // Transition duration

    ${(props) => props.theme.breakpoint.LtMd} {
        --header-gap: ${size(2.5)};
    }

    position: sticky;
    top: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: var(--header-height);
    z-index: 60;

    // Shadow displayed when page is scrolled
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        box-shadow: 0px 8px 10px 0px rgba(var(--c-overlay-rgb), 0.03), 0px 2px 14px 0px rgba(var(--c-overlay-rgb), 0.03),
            0px 2px 5px 0px rgba(var(--c-overlay-rgb), 0.09);
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms ease;
    }

    ${({ $variant, $menuOpen }) =>
        $variant === "transparent"
            ? css`
                  margin-bottom: calc(var(--header-height) * -1);
                  color: var(--c-overlay-fg);

                  &::after {
                      content: "";
                      position: absolute;
                      inset: 0;
                      background-image: linear-gradient(rgba(var(--c-overlay-rgb), 0.5), rgba(var(--c-overlay-rgb), 0));
                      z-index: -1;
                      transition: opacity 200ms ease;
                  }

                  ${$menuOpen &&
                  css`
                      background-color: var(--c-bg-main);
                      color: var(--c-text-main);

                      &::after {
                          display: none;
                      }
                  `}

                  // Overwrite position if RootMains children is set to display none by
                  // React Suspense/fallback to avoid header placed above the footer
                  body:has(${RootMain} > *[style*="display: none"]) & {
                      background-color: var(--c-bg-main);
                      color: var(--c-text-main);

                      &::after {
                          display: none;
                      }
                  }

                  .page-scrolled & {
                      background-color: var(--c-bg-main);
                      color: var(--c-text-main);
                      transition: background-color var(--_td) ease, color var(--_td) ease;

                      &::before {
                          transition-delay: var(--_td);
                      }

                      &::after {
                          opacity: 0;
                      }
                  }
              `
            : css`
                  background-color: var(--c-bg-main);
                  color: var(--c-text-main);
              `}

    ${({ $menuOpen }) =>
        $menuOpen &&
        css`
            :not(.page-scrolled) & {
                transition: none;
            }
        `}

    .page-scrolled & {
        &::before {
            opacity: 1;
        }
    }
`;

export const Inner = styled.div.withConfig({ componentId: 'sc-drvkj-1' })`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: var(--header-gap);
`;

export const Logo = styled.div.withConfig({ componentId: 'sc-drvkj-2' })`
    width: 170px;

    ${(props) => props.theme.breakpoint.LtMd} {
        width: 120px;
    }

    // Custom size for NO
    ${(props) => props.theme.brand.drive} {
        width: 130px;

        ${(props) => props.theme.breakpoint.LtMd} {
            width: 95px;
        }
    }

    svg {
        display: block;
        width: 100%;
        fill: currentColor;
    }
`;

export const LogoLink = styled(RRDLink).withConfig({ componentId: 'sc-drvkj-3' })``;

export const Main = styled.div.withConfig({ componentId: 'sc-drvkj-4' })`
    flex-shrink: 0;

    ${(props) => props.theme.breakpoint.Md} {
        display: flex;
        align-items: center;
        gap: ${size(4)};
    }
`;

export const MainNavList = styled.nav.withConfig({ componentId: 'sc-drvkj-5' })`
    display: flex;
    align-items: center;
    gap: ${size(3)};

    ${(props) => props.theme.breakpoint.LtMd} {
        display: none;
    }
`;

export const MainNavItem = styled.div.withConfig({ componentId: 'sc-drvkj-6' })`
    display: block;

    ${(props) => props.theme.breakpoint.LtMd} {
        & + & {
            border-top: 1px solid var(--c-border-main);
        }
    }
`;

export const MainNavLink = styled(RRDLink).withConfig({ componentId: 'sc-drvkj-7' })`
    display: block;

    ${(props) => props.theme.breakpoint.LtMd} {
        font-weight: var(--font-weight-bold);
        padding: ${size(2.25)} 0;
    }

    ${(props) => props.theme.breakpoint.Md} {
        font-weight: var(--font-weight-medium);
        font-size: ${(props) => props.theme.font.size.small};

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const QuickActions = styled.div.withConfig({ componentId: 'sc-drvkj-8' })`
    display: flex;
    align-items: center;
    gap: ${size(1)};
`;

interface QuickActionProps {
    $hasNotifications?: boolean;
}

const QuickAction = styled(ButtonReset).withConfig({ componentId: 'sc-drvkj-9' })<QuickActionProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${size(5)};
    height: ${size(5)};
    border-radius: 50%;
    font-size: ${(props) => props.theme.font.size.regular};
    transform-origin: 50% 50%;
    transition: transform 200ms ease;

    &::before {
        content: "";
        position: absolute;
        inset: 1px;
        background: var(--c-overlay);
        border-radius: inherit;
        mix-blend-mode: soft-light;
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:active {
        transform: scale(0.9);
    }

    &:not([disabled]):hover {
        &::before {
            opacity: 1;
        }
    }

    &[disabled] {
        opacity: 0.37;
        cursor: not-allowed;
    }

    ${({ $hasNotifications }) =>
        $hasNotifications &&
        css`
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: ${size(1)};
                height: ${size(1)};
                border-radius: 50%;
                background-color: var(--c-notification-bg);
            }
        `}
`;

export const QuickActionProfile = styled(QuickAction).withConfig({ componentId: 'sc-drvkj-10' })`
    background-color: var(--c-accent-alt-bg);
    color: var(--c-accent-alt-fg);

    &:has(img) {
        background-color: transparent;
    }
`;

export const QuickActionProfileImage = styled.img.withConfig({ componentId: 'sc-drvkj-11' })`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
`;

const QuickActionMenu = styled(QuickAction).withConfig({ componentId: 'sc-drvkj-12' })`
    transition: transform var(--menu-btn-transition-duration) ease;
`;

export const QuickActionFluid = styled(QuickAction).withConfig({ componentId: 'sc-drvkj-13' })`
    width: auto;
    background: var(--c-secondary-bg);
    color: var(--c-secondary-fg);
    border-radius: 100vmax;
    font-size: var(--font-size-small);
    padding: 0 ${size(1)};

    ${(props) => props.theme.breakpoint.Md} {
        padding: 0 ${size(2)} 0 ${size(1.5)};
    }

    ${Icon} {
        font-size: ${size(3)};
    }
`;

export const QuickActionFluidText = styled.span.withConfig({ componentId: 'sc-drvkj-14' })`
    font-weight: var(--font-weight-medium);
    margin-left: ${size(0.5)};

    ${(props) => props.theme.breakpoint.LtMd} {
        display: none;
    }
`;

export const QuickActionMenuOpen = styled(QuickActionMenu).withConfig({ componentId: 'sc-drvkj-15' })`
    background-color: var(--c-positive-bg);
    color: var(--c-positive-fg);
    transition-delay: var(--menu-btn-transition-duration);
`;

export const QuickActionMenuClose = styled(QuickActionMenu).withConfig({ componentId: 'sc-drvkj-16' })`
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--c-bg-alt);
    color: var(--c-text-main);
    transform: scale(0);
`;

interface QuickActionMenuWrapperProps {
    $menuOpen?: boolean;
}

export const QuickActionMenuWrapper = styled.div.withConfig({ componentId: 'sc-drvkj-17' })<QuickActionMenuWrapperProps>`
    --menu-btn-transition-duration: 100ms;

    position: relative;

    ${({ $menuOpen }) =>
        $menuOpen &&
        css`
            ${QuickActionMenuOpen} {
                transform: scale(0);
                transition-delay: 0ms;
            }

            ${QuickActionMenuClose} {
                transform: scale(1);
                transition-delay: var(--menu-btn-transition-duration);
            }
        `}
`;

export const FoldoutMenu = styled.div.withConfig({ componentId: 'sc-drvkj-18' })`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--c-bg-main);
    color: var(--c-text-main);
    border-top: 1px solid var(--c-border-main);
    padding: var(--header-gap) 0;
    overflow: hidden;
    box-shadow: 0 8px 8px rgba(var(--c-overlay-rgb), 0.1);
`;

export const MenuList = styled.nav.withConfig({ componentId: 'sc-drvkj-19' })`
    display: flex;
    flex-direction: column;
    gap: ${size(2)};

    & + & {
        margin-top: ${size(4)};
    }
`;

export const FoldoutMenuLinkText = styled.span.withConfig({ componentId: 'sc-drvkj-20' })``;

interface FoldoutMenuLinkProps {
    $large?: boolean;
}

export const FoldoutMenuLink = styled(RRDLink).withConfig({ componentId: 'sc-drvkj-21' })<FoldoutMenuLinkProps>`
    display: block;
    font-weight: var(--font-weight-medium);

    &:hover ${FoldoutMenuLinkText} {
        text-decoration: underline;
    }

    ${({ $large }) =>
        $large &&
        css`
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-bold);
        `}
`;

export const MenuLinkBadge = styled.span.withConfig({ componentId: 'sc-drvkj-22' })`
    margin-left: ${size(0.5)};
    padding: ${size(0.25)} ${size(1)};
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-bold);
    color: var(--c-text-muted);
    background: var(--c-bg-alt);
    text-decoration: none;
`;
