import {
    CONTAINER_MAX_WIDTH,
    MEDIA_QUERY_MAX_LG,
    MEDIA_QUERY_MAX_MD,
    MEDIA_QUERY_MAX_SM,
    MEDIA_QUERY_MIN_LG,
    MEDIA_QUERY_MIN_MD,
    MEDIA_QUERY_MIN_SM,
    TEXT_CONTENT_MAX_WIDTH,
} from "./constants";

export const theme = {
    // Selectors for brand specific styles
    brand: {
        drive: "[data-theme='drive'] &",
        driveRoot: "&[data-theme='drive']",
        wayke: "[data-theme='wayke'] &",
        waykeRoot: "&[data-theme='wayke']",
    },
    // Selectors for breakpoint specific styles
    breakpoint: {
        Sm: `@media (min-width: ${MEDIA_QUERY_MIN_SM})`,
        LtSm: `@media (max-width: ${MEDIA_QUERY_MAX_SM})`,
        Md: `@media (min-width: ${MEDIA_QUERY_MIN_MD})`,
        LtMd: `@media (max-width: ${MEDIA_QUERY_MAX_MD})`,
        Lg: `@media (min-width: ${MEDIA_QUERY_MIN_LG})`,
        LtLg: `@media (max-width: ${MEDIA_QUERY_MAX_LG})`,
    },
    // Font settings
    font: {
        lineHeight: {
            main: "var(--line-height-main)",
            thin: "var(--line-height-thin)",
        },
        size: {
            xsmall: "var(--font-size-xsmall)",
            small: "var(--font-size-small)",
            regular: "var(--font-size-regular)",
            large: "var(--font-size-large)",
        },
    },
    // Masks used to mask elements
    mask: {
        // Creates a mask that fades out the element
        gradient: {
            toTop: "linear-gradient(to top, #000, transparent)",
            toBottom: "linear-gradient(to bottom, #000, transparent)",
            toLeft: "linear-gradient(to left, #000, transparent)",
            toRight: "linear-gradient(to right, #000, transparent)",
        },
    },
    zIndex: {
        pageLoader: "90",
        floatingAlert: "95",
        modal: "110",
    },
    size: {
        containerMaxWidth: CONTAINER_MAX_WIDTH,
        containerGutter: "3rem",
        containerGutterLtMd: "1rem",
        contentMaxWidth: TEXT_CONTENT_MAX_WIDTH,
        borderRadius: "0.1875rem",
        inputHeight: "3rem",
    },
    boxShadow: {
        strong: "0px 8px 10px rgba(0, 0, 0, 0.03), 0px 2px 14px rgba(0, 0, 0, 0.03), 0px 2px 5px rgba(0, 0, 0, 0.09)",
        inset: "inset 0px 1px 3px rgba(0, 0, 0, 0.2)",
    },
};

export type Theme = typeof theme;

export default theme;
